import React, {useEffect} from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import {Link} from 'react-scroll';
import './Header.css';


import { useSelector, useDispatch } from 'react-redux';
import {SET_SHOW_MODAL} from '../../actions/types';



function HeaderMain(props) {
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    const SocialShare = [
        {Social: <FaFacebookF /> , link: appUI.realEstateAgentTexts.personal.socials.fb},
        {Social: <FaLinkedinIn /> , link: appUI.realEstateAgentTexts.personal.socials.linkedIn},
        {Social: <FaInstagram /> , link: appUI.realEstateAgentTexts.personal.socials.ig},
        {Social: <FaTwitter /> , link: appUI.realEstateAgentTexts.personal.socials.twitter},
    ]
    useEffect(() => {
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
        console.log('All assets are loaded');
        });
    });
     
        
    function menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    function CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    function renderRealEstate(){
        if(appUI.realEstateAgentTexts.personal.displayRealEstate){
            return<li><Link activeClass="is-current" to="real-estate" smooth={true}  spy={true} onClick={CLoseMenuTrigger}> Nemovitosti </Link></li>;
        }
        else return null;
    }
        function renderTestimonial(){
            if(appUI.realEstateAgentTexts.personal.testimonials.length){
                return <li><Link activeClass="is-current" to="testimonial" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}> Reference </Link></li>;
            }
            else return null;
        }
    function renderNews(){
        if(appUI.realEstateAgentTexts.personal.displayNews){
            return<li><Link activeClass="is-current" to="news" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}> Články </Link></li>;
        }
        else return null;
    }
    function stickyHeader () {}
    function openServices(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        var element = elements[0];
        element.parentElement.querySelector('.submenu').classList.toggle("active");
        element.classList.toggle("open");
    }

    window.addEventListener('scroll', function() {
        var value = window.scrollY;
        if (value > 100 )  {
            document.querySelector('.header--fixed').classList.add('sticky')
        }else {
            document.querySelector('.header--fixed').classList.remove('sticky')
        }
    });

    /*
    var elements = document.querySelectorAll('.has-droupdown > a');
    for(var i in elements) {
        if(elements.hasOwnProperty(i)) {
            elements[i].onclick = function() {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }
*/
    
    const { color='default-color' } = props;
   

      
        
        return(
            <header className={`header-area formobile-menu header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                        <Link 
                            to={`top`} 
                            smooth={true} offset={-100}
                            >
                                <h2 className="fw-light header-logo text-center my-auto" dangerouslySetInnerHTML={{__html: appUI.realEstateAgentTexts.personal.name}}></h2>
                        </Link>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu" >
                                <li><Link activeClass="is-current" to="about" smooth={true}  spy={true} onClick={CLoseMenuTrigger}> O mně </Link></li>
                                <li><Link activeClass="is-current" to="service" smooth={true} offset={-20}  spy={true} onClick={CLoseMenuTrigger}> Služby </Link></li>
                                {renderRealEstate()}
                                {renderNews()}
                                {renderTestimonial()}
                                <li className="has-droupdown submenu_calculators"><a onClick={() => openServices()}> Online služby </a>
                                    <ul className="submenu">
                                        <li><a onClick={() =>  dispatch({type: SET_SHOW_MODAL, payload: {show: true, service: 'morgage_estimate'}})}  target="_blank">Hypoteční kalkulačka</a></li>
                                        <li><a  onClick={() =>  dispatch({type: SET_SHOW_MODAL, payload: {show: true, service: 'real_estate_valuation'}})}  target="_blank">Ocenění nemovitosti</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    !val.link.endsWith('/') ? <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li> : null
                                ))}
                            </ul>
                        </div>
                        <div className="header-btn">
                            <Link 
                            to={`contact`} 
                            smooth={true} offset={-20}
                            className="rn-btn-main-page" >
                                <span>Kontakt</span>
                            </Link>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
}

export default HeaderMain;

