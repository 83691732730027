import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import RealEstateForm from './RealEstateForm';
import FormCompleted from './FormCompleted';
import RealEstateContactForm from './RealEstateContactForm';
import {SET_REAL_ESTATE_VALUATION_STEP, SUBMIT_REAL_ESTATE_VALUATION} from '../../actions/types';
import {submitRealEstateEstimateData} from '../../actions/onlineServiceActions';
import Loading from '../common/Loading';

function RealRestateValuation(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);

    function handleRealEstateFormSubmit(formValues){
        dispatch({type: SET_REAL_ESTATE_VALUATION_STEP, payload: 2})
        dispatch({type: SUBMIT_REAL_ESTATE_VALUATION, payload: formValues})
    }
    function handleRealEstateContactFormSubmit(formValues){
      
      dispatch({type: SET_REAL_ESTATE_VALUATION_STEP, payload: 3})
      dispatch(submitRealEstateEstimateData(formValues));
  }
      function renderModalBody(){
        if(appUI.realEstateEvaluationStep === 1){
          return <RealEstateForm handleFormSubmit={handleRealEstateFormSubmit}/>;
        }
        else if(appUI.realEstateEvaluationStep === 2){
          return <RealEstateContactForm handleFormSubmit={handleRealEstateContactFormSubmit}/>;
        }
        
        else if(appUI.submittingServiceData){
          return <Loading message="Zpracováváme Váš požadavek.."/>
        }
        else if(appUI.realEstateEvaluationStep === 3){
          return <FormCompleted />;
        }
      }

    return(
        <>
          <Modal.Body>
          <Container>
          {renderModalBody()}
          
        </Container>
        </Modal.Body>
      
        </>
    );
}

export default RealRestateValuation;