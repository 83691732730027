import React from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import {SET_SUBMIT_CONTACT_MESSAGE} from '../../actions/types';
import '../../component/common/Modal.css';

function ContactSubmited(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    const textContent = appUI.realEstateAgentTexts.personal.homePage.text;

    if(appUI.submitContactMessage === 'success'){
    return(
        <div className="loading-wrapper ">
             <div className="aligner-item--top">
               
                <span className="d-flex justify-content-center "><h2 className="fw-light text-center">Děkuji za Vaši zprávu.</h2></span>
                <hr/>
                <span className="d-flex justify-content-center "><p className="lead text-center fw-light">Brzy se Vám ozvu.</p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Pokud máte další přání, neváhejte využít našich online služeb nebo mě kontaktovat na přímo:</p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Tel: <a className="contact-href fw-bold" href={textContent.hrefMobile}>{textContent.contactMobile}</a> </p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">E-mail:  <a className="contact-href fw-bold" href={textContent.hrefEmail}>{textContent.contactEmail} </a> </p></span><br/>
                <span className="d-flex justify-content-center">
                <Button className="fw-light shadow-sm" variant="outline-dark" size="lg" 
                onClick={() => {
                    dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: ''});
                }}
                >Beru na vědomí</Button>
                </span>
             </div>
           
        </div>
    )
            
    }
    else if(appUI.submitContactMessage === 'error'){
    return(
        <div className="loading-wrapper ">
             <div className="aligner-item--top">
                <span className="d-flex justify-content-center "><h2 className="fw-light text-center">Děkuji za Vaši zprávu.</h2></span>
                <hr/>
                <span className="d-flex justify-content-center "><p className="lead text-center fw-light text-danger">Ale bohužel došlo k chybě.</p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Poprosím Vás abyste si zkontrolovali připojení k internetu a zkusili zprávu poslat znovu nebo mě kontaktovali na přímo:</p></span><br/> 
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Tel: <a className="contact-href fw-bold" href={textContent.hrefMobile}>{textContent.contactMobile}</a> </p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">E-mail:  <a className="contact-href fw-bold" href={textContent.hrefEmail}>{textContent.contactEmail} </a> </p></span><br/>
                <span className="d-flex justify-content-center">
                <Button className="fw-light shadow-sm" variant="outline-dark" size="lg" 
                onClick={() => {
                    dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: ''});
                }}
                >Beru na vědomí</Button>
                </span>
             </div>
           
        </div>
    )
    }
    else return null;
}

export default ContactSubmited;