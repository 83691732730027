import {
    GET_REALESTATE_DATA,
    SET_REAL_ESTATE_LOADING_ERROR,
    GET_REALESTATE_AGENT_TEXTS,
    GET_COMMON_TEXTS

} from './types';
import realEstateAPI from '../apis/realEstateAPI';
import blobStorageAPI from '../apis/blobStorageAPI';
import SiteContent from '../SiteContent';
/*
name            id
Artur Tarasjuk: 38
Jakub Hanuš:    24
Jakub Zeman:    25
Karel Třmínek:  40
Lucie Berková:  21
Lukáš Berka:    2     
Obchod P4L:     19
Radek Spáčil:   26
Tomáš Rezler:   63
Tomáš Zikmunda: 41
*/ 
export function getRealEstateAgentProperties(){
    return async (dispatch, getState) => {
        const {realEstateAgentTexts} = getState().appUI;
        //Get collection of real estate agent with img src and infromation if sold or not and type (byt, rodinny dum,)
        let realEstateAgenID = realEstateAgentTexts.personal.p4lrkID;
       await realEstateAPI.get(`/properties?author=${realEstateAgenID}&per_page=100&_embed=wp:featuredmedia,wp:term`)
       .then(response => {
           console.log(response);
            dispatch({type: GET_REALESTATE_DATA, payload: response.data});
        })
        .catch(function (error) {
            dispatch({type: SET_REAL_ESTATE_LOADING_ERROR, payload: true});
        });

    
    }
}

export function getRealEstateAgentTexts(emptyCacheStorage, latestVersionOfData){
    return async (dispatch, getState) => {
        const {currentRealEstateAgent} = getState().appUI;
        //Get texts for agent
        //https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/SiteContent.json
        console.log('calling json file');
        console.log(currentRealEstateAgent);
       await blobStorageAPI.get('SiteContent.json')
       .then(response => {
            console.log(response.data);
            console.log(response)
            // If user doesnt have stored version it means he visits for the first time or cache was cleared and cache doesnt have to be cleared
           
           if(!latestVersionOfData){
            console.log("version of page not stored")
                // Save current version
                localStorage.setItem('latestVersionOfData', response.data.version);
              
                dispatch({type: GET_REALESTATE_AGENT_TEXTS, payload: response.data});
           }
              // Or user already has latest version
           else if(latestVersionOfData === response.data.version){
            console.log("version of page matches")
            console.log(latestVersionOfData);
            dispatch({type: GET_REALESTATE_AGENT_TEXTS, payload: response.data});
           }
           // else if users version of page doesnt matches than we have to clear the cache to get new version
           else if(response.data.version !== latestVersionOfData){
                console.log("version of page doesnt match")
                console.log(latestVersionOfData);
                localStorage.removeItem('latestVersionOfData');
         
                    emptyCacheStorage();
           }
        })
        .catch(function (error) {
            console.log(error);
            //dispatch({type: SET_REAL_ESTATE_LOADING_ERROR, payload: true});
        });
    }
}