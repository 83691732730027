import {
    SUBMIT_REAL_ESTATE_VALUATION,
    SUBMIT_MORGAGE_ESTIMATE,
    SUBMIT_MORGAGE_FORM,
    SET_SUBMIT_SERVICE_MESSAGE,
    SET_SUBMIT_CONTACT_MESSAGE,
    SET_SERVICE_SUBMITTING,
    SET_MAIN_CONTACT_SUBMITTING,
    SET_CONTACT_ALERT
} from './types';

import emailjs from 'emailjs-com';

// Handles submitting od morgage and customer data from morgage form
export function submitContactData(formData){
    return async (dispatch, getState) => {
        dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: true});
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        const {realEstateAgentTexts} = getState().appUI;
        const contactData = {
            //Customer contact data
            customer_full_name: `${formData.firstName} ${formData.lastName}`,
            customer_email: formData.email,
            tel: formData.tel,
            subject: formData.subject,
            message: !formData.message ? 'Zákazník nevyplnil zprávu': formData.message,
            web: window.location.origin.substring(window.location.origin.indexOf("//") +2, window.location.origin.length),
            realEstateAgentEmail: realEstateAgentTexts.personal.email,
            webLink: baseUrl
        }
       /*
        console.log(contactData);
        setTimeout(() => dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: false}), 2500);
        setTimeout(() => dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'success'}), 2500);
        
         */
   
        emailjs.send('service_30xg06c', 'template_iwqdonm', contactData, 'user_zIsu7IzIHTGx2JoId3yWZ')
        .then(function(response) {
            dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'success'});
            dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: false});
            console.log('SUCCESS!', response.status, response.text);
         }, function(error) {
            dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'error'});
            dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: false});
            console.log('FAILED...', error);
         });
         
    }
}


// Handles submitting od morgage and customer data from morgage form
export function submitMorgageData(formData){
    return async (dispatch, getState) => {
        dispatch({type: SET_SERVICE_SUBMITTING, payload: true});
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        const {morgageAmountValue, morgageResourcesValue, morgageLength, morgageEstimateValue, realEstateAgentTexts} = getState().appUI;
        const morgageData = {
            //Customer contact data
            customer_full_name: `${formData.firstName} ${formData.lastName}`,
            customer_email: formData.email,
            zip: formData.zip,
            tel: formData.tel,
            city: formData.city === '' ? 'Zákazník město neposkytnul': formData.city,
           
            //Additional data for morgage
            ucel:  formData.ucel,
            zdroje: formData.zdroje,
            fixace: formData.fixace,

            //Morgage values
            morgageAmountValue: `${parseInt(morgageAmountValue, 10).toLocaleString()} Kč`,
            morgageResourcesValue: `${parseInt(morgageResourcesValue, 10).toLocaleString()} Kč`,
            morgageLength: `${morgageLength} Let`,
            morgageEstimateValue: `${parseInt(morgageEstimateValue, 10).toLocaleString()} Kč`,
            web: window.location.origin.substring(window.location.origin.indexOf("//") +2, window.location.origin.length),
            realEstateAgentEmail: realEstateAgentTexts.personal.email,
            webLink: baseUrl
        }
          /*
        console.log(morgageData);
        setTimeout(() => dispatch({type: SET_SERVICE_SUBMITTING, payload: false}), 2500);
        setTimeout(() => dispatch({type: SET_SUBMIT_SERVICE_MESSAGE, payload: 'success'}), 2500);
      
         */
        emailjs.send('service_30xg06c', 'template_3290id8', morgageData, 'user_zIsu7IzIHTGx2JoId3yWZ')
        .then(function(response) {
            dispatch({type: SET_SUBMIT_SERVICE_MESSAGE, payload: 'success'});
            dispatch({type: SET_SERVICE_SUBMITTING, payload: false});
            console.log('SUCCESS!', response.status, response.text);
         }, function(error) {
            dispatch({type: SET_SUBMIT_SERVICE_MESSAGE, payload: 'error'});
            dispatch({type: SET_SERVICE_SUBMITTING, payload: false});
            console.log('FAILED...', error);
         });
        
    }
}

// Handles submitting od real eastate estimate and customer data from real estate estimate form
export function submitRealEstateEstimateData(formData){
    return async (dispatch, getState) => {
        dispatch({type: SET_SERVICE_SUBMITTING, payload: true});
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        const {
            realEstateEstimate,
            dispozice,
            druh_nemovitosti,
            stav_nemovitosti,
            typ_vlastnictvi,
            velikost,
            otherRealEstateInfo,
            realEstateAgentTexts
        } = getState().appUI;
        let otherRealEstateInfoText = '';
        if(otherRealEstateInfo.balkon){otherRealEstateInfoText += 'balkón, '}
        if(otherRealEstateInfo.bazen){otherRealEstateInfoText += 'bazén, '}
        if(otherRealEstateInfo.developerskeProjekty){otherRealEstateInfoText += 'developerský projekt, '}
        if(otherRealEstateInfo.garaz){otherRealEstateInfoText += 'garáž, '}
        if(otherRealEstateInfo.parkovaciStani){otherRealEstateInfoText += 'parkovací stání, '}
        if(otherRealEstateInfo.sklep){otherRealEstateInfoText += 'sklep, '}
        if(otherRealEstateInfo.terasa){otherRealEstateInfoText += 'terasa, '}
        if(otherRealEstateInfoText === ''){otherRealEstateInfoText = 'Klient nespecifikoval další informace.'}
        const realEstateData = {
            //Customer contact data
            customer_full_name: `${formData.firstName} ${formData.lastName}`,
            customer_email: formData.email,
            zip: formData.zip,
            tel: formData.tel,
            city: formData.city === '' ? 'Zákazník město neposkytnul.': formData.city,
            adress: formData.adress,

            //Real estate data
            realEstateEstimate: `${parseInt(realEstateEstimate, 10).toLocaleString()} Kč`,
            dispozice,
            druh_nemovitosti,
            stav_nemovitosti,
            typ_vlastnictvi,
            velikost_m: `${velikost} m2`,
            clientMessage: otherRealEstateInfo.customerMessage === '' ? 'Klient nepřidal vlastní popis nemovitosti.': otherRealEstateInfo.customerMessage,
            otherRealEstateInfoText,

            web: window.location.origin.substring(window.location.origin.indexOf("//") +2, window.location.origin.length),
            realEstateAgentEmail: realEstateAgentTexts.personal.email,
            webLink: baseUrl
        }
        /*
        console.log(realEstateData);
        setTimeout(() => dispatch({type: SET_SERVICE_SUBMITTING, payload: false}), 2500);
        setTimeout(() => dispatch({type: SET_SUBMIT_SERVICE_MESSAGE, payload: 'success'}), 2500);
        */
        emailjs.send('service_30xg06c', 'template_hkum32w', realEstateData, 'user_zIsu7IzIHTGx2JoId3yWZ')
        .then(function(response) {
            dispatch({type: SET_SUBMIT_SERVICE_MESSAGE, payload: 'success'});
            dispatch({type: SET_SERVICE_SUBMITTING, payload: false});
            console.log('SUCCESS!', response.status, response.text);
         }, function(error) {
            dispatch({type: SET_SUBMIT_SERVICE_MESSAGE, payload: 'error'});
            dispatch({type: SET_SERVICE_SUBMITTING, payload: false});
            console.log('FAILED...', error);
         });
             
    }
}