import React, { Component ,Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import './RealEstateList.css';

function RealEstateList(){
        const dispatch = useDispatch();
        const appUI = useSelector(state => state.appUI);
        let displayStart = 0;
        if(appUI.realEstateCurrentPage > 1){
            displayStart = (appUI.realEstateCurrentPage - 1) * 6;
        }
        const RealEstateList = appUI.realEstateList.slice(
            displayStart, 
            displayStart + 6
            );

        function renderList(){
            return RealEstateList.map((value , index) =>  {
                let category = value._embedded['wp:term'][0][0].name;
                if(value._embedded['wp:term'][0][0].name === 'Byt' && value._embedded['wp:term'][1].length){
                    if(value._embedded['wp:term'][1][0].name === 'K prodeji'){
                        category = 'Byt na prodej';
                    }
                    else{
                        category = 'Byt na pronájem';
                    }
                }
                let sold = null;
                if(value._embedded['wp:term'][3][0].name === 'PRODÁNO'){
                    sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Prodáno</h3>
                }
                else if(value._embedded['wp:term'][3][0].name === 'PRONAJATO'){
                    sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Pronajato</h3>
                }
                return (
                    <>
                       <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    {sold}
                                    <a href="/blog-details">
                                        <img  src={value._embedded['wp:featuredmedia'][0].source_url} className={`thumbnail property-image`} alt="Blog Images"/>
                                    </a>
                                </div>
                                <div className="content">
                                    
                                    <p className="blogtype">{category}</p>
                                    <h4 className="title"><a href={value.link} >{value.title.rendered}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn btn-white text-white" href={value.link} target="_blank">Detail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            });
        }
        return (
             <Fragment>
                 <div className="row">
                    {renderList()}
                 </div>
             </Fragment>
        );
}

export default RealEstateList;