import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from '../component/common/ScrollTop';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderSecondary";
import Footer from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";
import './Podminky.css';

class OsobniUdaje extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Obchodní podmínky' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Informace o zpracování osobních údajů</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner ">
                                        <p className="small-text">
                                        Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2,zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264 (dále jen „P4L“), jakožto poskytovatel software, IT řešení a marketingu a provozovatel webového portálu www.p4life.cz a www.p4life-rk.cz,při správě a zpracování osobních údajů svých klientů a spolupracujících osob plně respektuje zásady jejich ochrany a v souladu s platnými právními předpisy níže informuje subjekty osobních údajů o jejich právech, důvodech zpracování a další podstatné informace týkající se zpracování osobních údajů.

<br/><br/>Společnost P4L, s.r.o. se v plném rozsahu řídí pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně osobních údajů (dále jen „Nařízení“) a v souladu s tímto Nařízením přísně dodržuje všechny nastavené postupy.

<br/><br/>Pokud se jakákoli část tohoto dokumentu bude zdát nesrozumitelná nebo vyplyne-li potřeba dalšího podrobnějšího vysvětlení některé problematiky související se zpracováním osobních údajů, je možné obrátit se na naši emailovou adresu:obchod@p4life.cz.
                                        </p>
                                       
                                        <p className="small-text">
Obsah:

<br/><br/>1. Správce osobních údajů a jeho identifikace – kdo to je.
<br/>2. Rozsah zpracování osobních údajů.
<br/>3. Doba, po kterou jsou osobní údaje zpracovány.
<br/>4. Kdo má k osobním údajům přístup, komu jsou poskytovány. 
<br/>5. Automatizované zpracování osobních údajů.
<br/>6. Jaká jsou práva subjektu údajů.
<br/>7. Uplatnění práv subjektů údajů – klientů společnosti P4L.8. Souhlas se zpracováním osobních údajů.

</p>
<p className="small-text">
1. Správce osobních údajů a jeho identifikace – kdo to je<br/><br/>

Správcem je osoba, která disponuje s osobními údaji subjektu údajů a zároveň určuje účel a prostředky jejich zpracování. Společnost P4L je správcem osobních údajů, jelikož shromažďuje osobní údaje zájemců o porovnání nabídek hypoték na základě zadaných kritérií a dále pro tyto zájemce zajišťuje možnost zpracování hypoteční analýzy hypotečním specialistou s cílem rychlého a efektivního vyřízení žádosti o hypotéku.

<br/><br/>Kontaktní údaje a totožnost správce:

<br/><br/>Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, e-mail: obchod@p4life.cz.

<br/><br/>Pověřenec pro ochranu osobních údajů nebyl u Správce jmenován. Správce nemá zástupce pro plnění povinností ve smyslu Nařízení.
</p>
<p className="small-text">
2. Rozsah a kategorie zpracování osobních údajů.

<br/><br/>Společnost P4L zpracovává pouze ty osobní údaje, které jsou nezbytné pro řádnéposkytování služeb, plnění zákonných povinností a též k ochraně zájmů společnosti P4L. Shromažďujeme osobní údaje zájemců o naše služby nebo potenciálních zájemců, kteří nám ke zpracování osobních údajů a k oslovení udělili souhlas.

<br/><br/>Kategorie osobních údajů, které společnost P4L zpracovává:

<br/>a) identifikační údaje – jméno, příjmení, datum narození, adresa bydliště, podpis,u podnikatelských subjektů IČ a adresa sídla.

<br/>b) kontaktní údaje – e-mailová adresa a telefonní číslo, adresa místa bydliště – jméno, příjmení, datum narození, adresa bydliště, podpis, u podnikatelských subjektů IČ a adresa sídla.

<br/>c) sociodemografické údaje (věk, příjmy, splátky úvěru, počet dětí) – tyto údaje společnost P4L zpracovává pouze v případě, kdy si subjekt údajů sám dobrovolně


zvolí možnost rozšířených kritérií pro srovnání hypotéky na webovém portáluwww.p4life.cz a www.p4life-rk.cz, poskytnutí těchto údajů není povinné a záleží pouze na volbě subjektu údajů, zda má zájem o podrobnější výsledek srovnání hypotéky.

<br/><br/>Zpracování osobních údajů bez nutnosti udělení souhlasu:

<br/><br/>a) pro účely smluvního plnění

<br/>– poskytnutí osobních údajů pro účely srovnání a kalkulaci hypotéky na základě zadaných kritérií zájemce je smluvním požadavkem společnosti P4L.

<br/><br/>b) zpracování na základě plnění zákonných povinností

<br/>– společnost P4L zpracovává také osobní údaje, pokud jí tuto povinnost přikazuje zákon.

<br/><br/>c) zpracování na základě oprávněných zájmů společnosti P4L 
<br/>– zejména pro účely obhajoby právních nároků společnosti P4L

Zpracování osobních údajů s udělením souhlasu zájemce <br/>– za účelem nabídky finančního produktu – hypotéky:
<br/>– udělení souhlasu zájemcem je dobrovolné, zároveň však nezbytné, aby jej mohl dále kontaktovat hypoteční specialista (Poradce) za účelem zpracování individuální nabídky, hypoteční analýzy a informování zájemce o finančníchproduktech – hypotékách dle kritérií zadaných zájemcem do srovnávače/kalkulačky hypoték na webovém portálu www.p4life.cz a www.p4life- rk.cz.

<br/>– na základě uděleného souhlasu pro účely nabídky finančního produktu –hypotéky může být zájemce kontaktován formou e-mailové zprávy nebo telefonicky, další komunikace probíhá dle preferencí zájemce.

<br/>– osobní údaje mohou být za účelem zpracování nabídky hypotéky poskytnuty hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se zájemcem finanční služby,



<br/>– bez uděleného souhlasu nemůže být zájemce informován ani kontaktován s nabídkou individuálního hypotečního produktu, nelze s ním o možnostech sjednání hypotéky či jiného finančního produktu jednat, nabídka finančních produktů nemůže být více zpřesňována,

<br/>– zájemce může udělený souhlas kdykoli odvolat a to písemně na adresuobchod@p4life.cz, písemně dopisem na korespondenční adresu společnosti P4L– Komenského 446/6, Jablonec nad Nisou, 466 01.
</p>
<p className="small-text">
3.Doba, po kterou jsou osobní údaje zpracovány.
<br/><br/>Osobní údaje klientů společnost P4L zpracovává po dobu, po kterou svým klientům poskytuje své služby nebo po dobu trvání smlouvy. Po ukončení smluvního vztahu a po vypořádání veškerých závazků z takové smlouvy plynoucích a jinak s ní souvisejících uchovává společnost P4L osobní údaje klientů po dobu nezbytně nutnou, která je vyžadována dle příslušných právních předpisů, např. dle zákona o účetnictví, zákona o archivnictví a evidenci, zákona o dani z přidané hodnoty, atd. a dle obecných i speciálních promlčecích lhůt.U udělených souhlasů se zpracováním pro účely nabídky finančního produktu je doba uchování osobních údajů 3 roky od udělení souhlasu.Společnost P4L se při uchování osobních údajů řídí zásadou minimalizace údajů, v okamžiku, kdy odpadne účel zpracování osobních údajů nebo uplyne doba, po kterou je společnost P4L povinna osobní údaje uchovávat, osobní údaje jsou zcela vymazány ze systémů, případně vhodným způsobem anonymizovány. Osobní údaje tak nikdy nejsou zpracovávány nebo uchovávány déle, než je nezbytně nutné.
</p>
<p className="small-text">
4.Kdo má k osobním údajům přístup, komu jsou poskytovány.
<br/><br/>Osobní údaje klientů jsou zpřístupněny společnosti P4L jakožto jejich správci, dálepověřeným zaměstnancům společnosti P4L a smluvním zpracovatelům, se kterými má společnost P4L uzavřenou Smlouvu o zpracování osobních údajů, na základě které jsou tito smluvní zpracovatelé povinni osobní údaje zpracovávat v souladu s Nařízením. Smluvními zpracovateli jsou zejména hypoteční specialisté, tj. Poradci, poskytovatelé IT služeb, účetní a daňoví poradci.



<br/>Osobní údaje zpracovávané na základě uděleného souhlasu za účelem nabídky finančního produktu mohou být v souladu s uděleným souhlasemposkytnuty/předány jinému správci, a to konkrétnímu hypotečnímu specialistovi (dále jen „Poradce“), který má se společností P4L uzavřenou Smlouvu o spolupráci a dle zákona č. 257/2016 Sb., zákon o spotřebitelském úvěru, je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

<br/>Společnost P4L svým klientům garantuje, že osobní údaje, které zpracovává na základě uděleného souhlasu za účelem nabídky finančního produktu, nejsou v žádném případě předávány žádným externím call centrům ani marketingovýmspolečnostem zajišťujícím nejrůznější marketingové kampaně, vždy se jedná o konkrétní osobu hypotečního specialisty, která je smluvním partnerem společnosti P4L.
</p>
<p className="small-text">
5.Automatizované zpracování osobních údajů.
<br/><br/>Součástí poskytování služeb společnosti P4L je i automatizované zpracování osobních údajů. Při automatizovaném zpracování osobních údajů jsou používány automatické informační systémy, např. elektronické kalkulačky, software, IT aplikace. Cílem automatizovaného zpracování osobních údajů je rychléa individuální poskytnutí služby dle specifických požadavků a potřeb klientů.
</p>
<p className="small-text">
6.Jaká jsou práva subjektu údajů.
<br/><br/>a) Právo na informace týkající se zpracování osobních údajůKaždý subjekt zpracování osobních údajů má právo znát totožnost a kontaktní údaje na správce osobních údajů, jeho zástupce i na případné pověřence pro ochranu osobních údajů, dále má právo znát účel a právní základ zpracování osobních údajů, právo znát příjemce nebo kategorie příjemců osobních údajů, informace o předání osobních údajů do třetích zemí, dobu uložení osobních údajů, informaci o právu na odvolání souhlasu a o dalších právech subjektu údajů, možnost podat stížnost u Úřadu na ochranu osobních údajů, informaci, zda je poskytování osobních údajů zákonný či smluvní požadavek, informování o automatizovaném rozhodování a profilování.



<br/><br/>b) Právo na přístup k osobním údajům

<br/>Právo klienta požadovat od společnosti P4L, jakožto správce, potvrzení, zda jeho osobní údaje jsou či nejsou zpracovávány. Pokud zpracovávány jsou, tak máklient, jakožto subjekt údajů, právo získat přístup k těmto osobním údajům a k informacím o účelu jejich zpracování, kategoriích dotčených osobních údajů, příjemcích nebo kategoriích příjemců, kterým jsou, byly nebo mají být osobní údaje zpřístupněny, dobu zpracování osobních údajů, existence práva požadovat opravu, výmaz, omezení a možnosti vznést námitku proti zpracování, informace o právu podat stížnost u Úřadu pro ochranu osobních údajů, informace o zdroji osobních údajů, informace, zda dochází k automatizovanému rozhodování a profilování osobních údajů, informace o zárukách při předávání osobních údajů do třetích zemí nebo mezinárodním organizacím.

<br/>Subjekt údajů má právo požadovat od společnosti P4L kopie zpracovávaných osobních údajů. Při opakovaných a zjevně nepřiměřených žádostech je společnostP4L oprávněna účtovat klientům přiměřený poplatek na úhradu administrativních nákladů za pořízení kopie.

<br/>Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na adresu sídla nebo na e-mailovou adresu společnosti P4L.

<br/><br/>c) Právo na opravu osobních údajů

<br/>Subjekt údajů má právo žádat, aby společnost P4L bez zbytečného odkladu opravila nepřesné a doplnila neúplné osobní údaje, které se jej týkají. Základem uplatnění tohoto práva je sdělení skutečnosti vedoucí k opravě či doplnění společnosti P4L.

<br/><br/>d) Právo na výmaz osobních údajů

<br/>V případech, kdy pominul účel zpracování osobních údajů, byl odvolán souhlas ke zpracování osobních údajů a v dalších případech, které jsou podrobně uvedeny v čl. 17 odst. 1 písm. a) až f) Nařízení, je společnost P4L na základě Vaší žádosti povinna osobní údaje žádajícího klienta smazat. Pokud však není splněna zákonná podmínka pro výmaz, má společnost P4L právo si osobní údajeponechat. Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na korespondenční adresu společnosti P4L – Komenského 446/6,Jablonec nad Nisou, 466 01 nebo na e-mailovou adresu společnosti P4L.



<br/><br/>e) Právo na omezení zpracování osobních údajů

<br/>Na žádost klienta je společnost P4L povinna omezit zpracování jeho osobních údajů jen na nejnutnější zákonné důvody, pokud jsou splněny podmínky dle čl. 18 Nařízení. V obecné rovině lze toto právo uplatnit v případech, kdy není jasné, zda a kdy se budou muset osobní údaje odstranit.

<br/><br/>f) Právo vznesení námitky proti zpracování osobních údajů

<br/>V případech, kdy klient dojde ke zjištění, že společnost P4L zpracovává jeho osobní údaje v rozporu se zákonem nebo v rozporu s ochranou soukromého a osobního života klienta, má právo obrátit se přímo na společnost P4L s toutonámitkou, aby došlo k co nejrychlejšímu odstranění závadného stavu nebo k dostatečnému zdůvodnění nastalé situace. Námitky lze vznášet i proti automatizovanému rozhodování/profilování.

<br/><br/>g) Právo na přenositelnost údajů

<br/>Klient může požadovat, aby jeho osobní údaje, které společnosti P4L udělil, byly předány jinému správci dle požadavku klienta. Osobní údaje budou přeneseny v běžně používaném odpovídajícím formátu, nebude-li požadavku klienta bránit zákonná či jiná překážka.

<br/><br/>h) Právo podat podnět/stížnost na Úřad pro ochranu osobních údajů

<br/>S jakýmkoli podnětem či stížností týkající se zpracování osobních údajů se klient může obrátit přímo na Úřad pro ochranu osobních údajů, jakožto na dozorový orgán.
</p>
<p className="small-text">
7. Uplatnění práv subjektů údajů – klientů společnosti P4L.

<br/><br/>Jednotlivá práva uvedená v čl. 6 tohoto informačního dokumentu mohou subjekty údajů uplatnit u správce, společnosti P4L buď e-mailovou zprávou na adresu:obchod@p4life.cz nebo písemně dopisem na adresu společnosti P4L -Komenského 446/6, Jablonec nad Nisou, 466 01.

<br/><br/>Žádosti subjektů údajů je společnost P4L povinna vyřešit nejpozději ve lhůtě 1 měsíce ode dne přijetí žádosti. V odůvodněných případech zejména s ohledem na rozsah žádosti je společnost P4L oprávněna lhůtu



k vyjádření prodloužit na 2 měsíce, o nutnosti prodloužení společnost P4L subjektúdajů včas informuje.

<br/><br/>Dokument je pravidelně aktualizován, datum poslední aktualizace 25. 5. 2018.

<br/><br/>Souhlas se zpracováním osobních údajů

<br/>Tímto uděluji společnosti P4L s. r. o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, zastoupena jednatelem Lukášem Berkou, email: obchod@p4life.cz (dále jen „Správce“) souhlas se zpracováním osobních údajův rozsahu:

<br/>- jméno a příjmení
<br/>- emailová adresa
<br/>- telefonní číslo
<br/>- adresa trvalého bydliště
<br/>- věk žadatele
<br/>- měsíční příjem
<br/>- měsíční splátky úvěru
<br/>- členové domácnosti
<br/>- počet žadatelů
<br/>- platební historie

<br/><br/>(dále jen „Osobní údaje“)

<br/>za účelem:

<br/><br/>Osobní údaje budou použity za účelem zasílání informací, produktů, služeb, novinek a aktualit týkajících se finančního trhu tj. newsletterů a dále ke zpracování nabídky finančních produktů dle kritérií zadaných subjektemúdajů do srovnávače / kalkulačky hypoték na webovém portálu Správcehttp://www.p4life.cz a www.p4life-rk.cz, ke kontaktování subjektu údajůe-mailem či telefonicky a k dalšímu jednání o finančních produktech s hypotečním specialistou.

<br/><br/>Osobní údaje jsou zpracovávány 3 roky a může na základě jejich zpracování docházet k automatizovanému zpracování či profilování.



<br/><br/>Osobní údaje mohou být za účelem zpracování nabídky finančního produktu poskytnuty / předány jinému správci, a to hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

<br/><br/>Dále beru na vědomí, že tentosouhlas uděluji svobodně a dobrovolně a můžu jej kdykoliv odvolat formou písemné informace zaslané na adresuSprávce nebo na e-mailovou adresu obchod@p4life.cz, a to i před uplynutím doby, na níž jsem souhlas udělil/(a). Odvoláním tohoto souhlasu není dotčena zákonnost zpracování osobních údajů v době před odvoláním souhlasu.

<br/><br/>Více informací o zpracování mých osobních údajů je uvedeno v informačním dokumentu, který je k dispozici na webové adrese www.p4life.cz a www.p4life- rk.cz.

<br/><br/>V případě dotazů týkajících se zpracování mých osobních údajů se mohu obrátit na správce osobních údajů, a to prostřednictvím emailové adresy obchod@p4life.cz.
</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                   {/* Start Contact Area */}
                   <div id="contact" className="fix">
                                                <div className="rn-contact-area ptb--120 bg_color--1">
                                                    <ContactOne />
                                                </div>
                                            </div>
                                        {/* End Contact Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop />
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default OsobniUdaje;