import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appUIReducer from './ui/appUIReducer';

export default combineReducers({
    //data reducers
    form: formReducer,
    //UI reducers
    appUI: appUIReducer,

});