import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import Slider, { SliderTooltip } from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './Services.css';

import {SET_MORGAGE_AMOUNT, SET_MORGAGE_RESOURCES_AMOUNT, SET_MORGAGE_LENGTH, SET_MORGAGE_STEP, SET_MORGAGE_ESTIMATE_VALUE} from '../../actions/types';

const { Handle } = Slider;

function validate(values, props){
    const errors = {}
    if (!values.ucel) {
      errors.ucel = 'Povinné'
    }else if (values.ucel === '') {
      errors.ucel = 'Vyberte účel'
    }

    if (!values.zdroje) {
        errors.zdroje = 'Povinné'
      }else if (values.zdroje === '') {
        errors.zdroje = 'Vyberte původ zdrojů'
      }

      if (!values.fixace) {
        errors.fixace = 'Povinné'
      }else if (values.fixace === '') {
        errors.fixace = 'Vyberte délku fixace'
      }
  
 
  
    return errors
  }
function renderOptions(options){
  return options.map(item => {
      if(item.id === 0){
        return(
            <option key={item.id} value={''}>{item.name} </option>
        );
      }
      return(
          <option key={item.id} value={item.name}>{item.name}</option>
      );
  })
}
function renderSelect({ input, options, label, meta: { touched, error } }){
 
    return(
        <>
            <Form.Label>{label}</Form.Label>
            <Form.Control value={options[0]} as={'select'} {...input} size="sm">
                {renderOptions(options)}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </>
    );
 
}

function renderRadio({ label, meta: { touched, error }}){
    return(
        <>
             <Form.Check
          required
          label={label}
            />
          <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </>
    );
}
// initial wrapper - note alterations
const ReduxSlider = props => {
  const dispatch = useDispatch();
  const appUI = useSelector(state => state.appUI);
    const { input: { value, onChange } } = props
    return (
        <Slider
            defaultValue={props.input.value}
            onChange={(value) => dispatch({type: props.actionType, payload: value[0]})}
            {...props} 
            value={props.sliderValue}
        />
    )
}
const handle = props => {
    
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

var MorgageForm = (props) =>{
    const dispatch = useDispatch();

    const { handleSubmit, valid, initialValues, t} = props;
    const appUI = useSelector(state => state.appUI);
    const morgageAmount = appUI.morgageAmountValue;
    function renderMonthlyPayment(){
        let p = appUI.morgageAmountValue - appUI.morgageResourcesValue; //principle / initial amount borrowed
        let i = appUI.realEstateAgentTexts.common.interestRate / 100 / 12; //monthly interest rate
        let n =  appUI.morgageLength * 12; //number of payments months
        let monthlyPayment = Math.floor(p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
        let displayValue = `${monthlyPayment.toLocaleString()} Kč`;
        if(monthlyPayment < 0){
            displayValue = 'Výše vlastních zdrojů musí být nižší než výše hypotéky.'
        }else if(monthlyPayment === 0){
            displayValue = 'Měsíční splátka nemůže být nulová.'
        }
        return(
            <Row >
                <Col sm="12" md="4"></Col>
                <Col sm="12" md="4" className="d-flex justify-content-center">
                    <figure class="text-center payment-section shadow ">
                        <blockquote class="blockquote ">
                            <p>{displayValue}</p>
                        </blockquote>
                        <figcaption class="blockquote-footer">
                            orientační měsíční splátka<br/> při úroku {appUI.realEstateAgentTexts.common.interestRate}% p.a.
                        </figcaption>
                    </figure>
                </Col>
                <Col sm="12" md="4" className="d-flex justify-content-center">
                    <Button size="lg" block  type="submit" 
                    variant="outline-primary"
                    className="fw-light shadow-lg get-offer-button border-0 "
                    onClick={() => {dispatch({type: SET_MORGAGE_STEP, payload: 2});  dispatch({type: SET_MORGAGE_ESTIMATE_VALUE, payload: monthlyPayment});}}
                    >

                        Získat nabídku
                    </Button>
                </Col>
               
            </Row>
        )
    }
    function handleFormSubmit(formValues){
        props.handleFormSubmit(formValues);
    }
    const ucelArray = [
        {id: 0, name: 'Vyberte účel..'},
        {id: 1, name: 'Koupě'},
        {id: 2, name: 'Rekonstrukce'},
        {id: 3, name: 'Refinancování'},
        {id: 4, name: 'Vypořádání SJM'},
        {id: 5, name: 'Americká hypotéka'},
        {id: 6, name: 'Nemovitost na pronájem'},
    ]
    const zdrojeArray = [
        {id: 0, name: 'Vyberte původ..'},
        {id: 1, name: 'Vlastní zdroje'},
        {id: 2, name: 'Zástava nemovitostí'},
    
    ]

    const fixaceArray = [
        {id: 0, name: 'Vyberte délku..'},
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
        {id: 6, name: 6},
        {id: 7, name: 7},
        {id: 8, name: 8},
        {id: 9, name: 9},
        {id: 10, name: 10},
    ]
    // these props will get passed to the morgageSliders
    const morgageAmountSliderProps = {
        
    
        defaultValue:[300000],
        marks:{
            300000: '300 000',
            5000000: '5 mil',
            10000000: '10 mil',
            15000000: '15 mil',
            20000000: '20 mil'
        },
        pushable:true,
        min:300000,
        max:20000000,
        step: 100000,
    }

    const morgageResourcesSliderProps = {
        defaultValue:[50000],
        marks:{
            50000: '50 000',
            5000000: '5 mil',
            10000000: '10 mil',
        },
        pushable:true,
        min:50000,
        max:10000000,
        step: 10000,
    }
    const morgageLengthSliderProps = {
        defaultValue:[5],
        marks:{
            5: '5',
            10: '10',
            15: '15',
            20: '20',
            35: '35'
        },
        pushable:true,
        min:5,
        max:30,
        step: 1,
    }
    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
            {renderMonthlyPayment()}
            <hr />
                {/* Morgage amount slider */}
                <Form.Group className="mb-3 shadow slider-section">
                <div className="box-header small-space-bottom">
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column md="auto" >
                    Výše hypotéky:
                    </Form.Label>
                    <Col md="auto">
                    <NumberFormat 
                        thousandSeparator={true}
                        suffix={' Kč'}
                        value={morgageAmount}
                        onValueChange={(value) => dispatch({type: SET_MORGAGE_AMOUNT, payload: value.value})}
                        className="border-end-0 border-start-0"
                    />
                    </Col>
                </Form.Group>
                
                
                </div>
                        <div className="box-body">
                            <Field
                                name="morgageSlider"
                                component={ReduxSlider}
                                props={morgageAmountSliderProps}
                                actionType={SET_MORGAGE_AMOUNT}
                                sliderValue={[appUI.morgageAmountValue]}
                            />
                        </div>
                </Form.Group>
                
                {/* Morgage own resources slider */}
                <Form.Group className="mb-3 shadow slider-section">
                <div className="box-header small-space-bottom">
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column md="auto" >
                    Vlastní zdroje:
                    </Form.Label>
                    <Col md="auto">
                    <NumberFormat 
                        thousandSeparator={true}
                        suffix={' Kč'}
                        value={appUI.morgageResourcesValue}
                        onValueChange={(value) => dispatch({type: SET_MORGAGE_RESOURCES_AMOUNT, payload: value.value})}
                        className="border-end-0 border-start-0"
                    />
                    </Col>
                </Form.Group>
                
                
                </div>
                        <div className="box-body">
                            <Field
                                name="morgageResourcesSlider"
                                component={ReduxSlider}
                                props={morgageResourcesSliderProps}
                                actionType={SET_MORGAGE_RESOURCES_AMOUNT}
                                sliderValue={[appUI.morgageResourcesValue]}
                            />
                        </div>
                </Form.Group>
        {/* Morgage length slider */}
        <Form.Group className="mb-3  shadow slider-section">
                <div className="box-header small-space-bottom">
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column md="auto" >
                    Splatnost:
                    </Form.Label>
                    <Col md="auto">
                    <NumberFormat 
                        thousandSeparator={true}
                        suffix={' Let'}
                        value={appUI.morgageLength}
                        onValueChange={(value) => dispatch({type: SET_MORGAGE_LENGTH, payload: value.value})}
                        className="border-end-0 border-start-0"
                    />
                    </Col>
                </Form.Group>
                
                
                </div>
                        <div className="box-body">
                            <Field
                                name="morgageLengthSlider"
                                component={ReduxSlider}
                                props={morgageLengthSliderProps}
                                actionType={SET_MORGAGE_LENGTH}
                                sliderValue={[appUI.morgageLength]}
                            />
                        </div>
                </Form.Group>
            <span  className="d-flex justify-content-end">
          
            </span>
           
         
        </Form>
    )
}

export default reduxForm({
    form: 'morgageForm', // a unique identifier for this form
    
    initialValues: {
        morgageSlider: [300000],
        morgageResourcesSlider: [50000],
        morgageLengthSlider: [5]
    },
    validate            // <--- validation function given to redux-form
  })(MorgageForm)
