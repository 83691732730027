import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CustomCheckBox from '../../component/onlineServices/CustomCheckBox';
import Captcha from './Captcha';
import './Contact.css';

function validate(values, props){
    const errors = {}
      if (!values.souhlas) {
        errors.souhlas = 'Bez Vašeho souhlasu požadavek nemůžeme zpracovat'
      }
      if (!values.subject) {
        errors.subject = 'Povinné'
      } else if (values.subject.length > 35) {
        errors.subject = 'Předmět nesmí přesáhnout délku 35 znaků'
      }else if (values.subject.length < 3) {
        errors.subject = 'Předmět musí být alespoň 3 znaky dlouhý'
      }
      if (!values.firstName) {
        errors.firstName = 'Povinné'
      } else if (values.firstName.length > 35) {
        errors.firstName = 'Jméno nesmí přesáhnout délku 35 znaků'
      }else if (values.firstName.length < 3) {
        errors.firstName = 'Jméno musí být alespoň 2 znaky dlouhé'
      }
      if (values.tel) {
        if (values.tel.length < 9) {
          errors.tel = 'Zadejte platné číslo'
        } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/i.test(values.tel)) {
          errors.tel = 'Zadejte platné číslo'
        }
      }
      if (!values.lastName) {
        errors.lastName = 'Povinné'
      } else if (values.lastName.length > 50) {
        errors.lastName = 'Příjmení nesmí přesáhnout délku 50 znaků'
      }else if (values.lastName.length < 3) {
        errors.lastName = 'Jméno musí být alespoň 2 znaky dlouhé'
      }
      if (!values.email) {
        errors.email = 'Povinné'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Neplatný email'
      }
      if (!values.captcharesponse) {
        errors.captcharesponse = 'Prosím ověřte že nejste robot.';
    }
    
  
    return errors
  }



function renderField({ input, customAS, label, customRows, type, placeholder, meta: { touched, error } }){
 
      return(
          <>
            <Form.Label>{label}</Form.Label>
            <Form.Control  {...input} 
            placeholder={placeholder} 
            type={type}
            as={customAS === null? null : customAS}
            rows={customRows === null? null : customRows}
            isInvalid={touched && error}
            isValid={touched && !error}
            />
            <Form.Control.Feedback type="invalid">
                  {error}
              </Form.Control.Feedback>
          </>
      );
  }
var MainContactForm = (props) =>{

    const { handleSubmit, valid, initialValues, t} = props;


    function handleFormSubmit(formValues){
        props.handleFormSubmit(formValues);
    }
 
    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
                <Form.Group className="space-bottom">
                    <Row>
                        <Col>
                            <Field name="firstName" type="text" component={renderField} placeholder="Jméno * "/> 
                        </Col>
                        <Col>
                            <Field name="lastName"  type="text" component={renderField} placeholder="Příjmení * " /> 
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className="space-bottom">
                    <Field name="email" type="email" component={renderField}  placeholder="Váš e-mail *"/>
                </Form.Group>
                <Form.Group className="space-bottom">
                    <Field name="tel" type="tel" component={renderField}  placeholder="Telefon: např. 111 222 333"/>
                </Form.Group>
                    <Form.Group className="space-bottom" >
                        <Field name="subject" type="text" component={renderField}  placeholder="Předmět e-mailu * "/>
                    </Form.Group>
                    <Form.Group className="small-space-bottom">
                        <Field name="message" type="text" customAS="textarea" customRows={3} component={renderField}  placeholder="Vaše zpráva"/>
                    </Form.Group>
            <Form.Group className="small-space-bottom">
            <CustomCheckBox name="souhlas"  feedback="Bez Vašeho souhlasu požadavek nemůžeme zpracovat." privacyAgreement={true}/>
            </Form.Group>

          
            <Row className="mb-3">

              <Form.Group as={Col} md="8" className="smallest-space-bottom">
              <Field name='captcharesponse' component={Captcha}/>
              </Form.Group>

              <Form.Group as={Col} className="d-flex justify-content-end" md="4" >
                  <button type="submit" 
                      className="rn-button-style--2 btn-solid ">
                          Odeslat 
                  </button>
              </Form.Group>
            </Row>
        </Form>
    )
}

export default reduxForm({
    form: 'mainContactForm', // a unique identifier for this form
    validate            // <--- validation function given to redux-form
  })(MainContactForm)


  