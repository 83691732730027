import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import './Services.css';
import CustomCheckBox from './CustomCheckBox';
import Captcha from '../../elements/contact/Captcha';

function validate(values, props){
    const errors = {}
    if (!values.ucel) {
      errors.ucel = 'Povinné'
    }else if (values.ucel === '') {
      errors.ucel = 'Vyberte účel'
    }

    if (!values.zdroje) {
        errors.zdroje = 'Povinné'
      }else if (values.zdroje === '') {
        errors.zdroje = 'Vyberte původ zdrojů'
      }

      if (!values.fixace) {
        errors.fixace = 'Povinné'
      }else if (values.fixace === '') {
        errors.fixace = 'Vyberte délku fixace'
      }
      if (!values.souhlas) {
        errors.souhlas = 'Bez Vašeho souhlasu požadavek nemůžeme zpracovat'
      }
      if (!values.adress) {
        errors.adress = 'Povinné'
      }
      if (!values.firstName) {
        errors.firstName = 'Povinné'
      } else if (values.firstName.length > 35) {
        errors.firstName = 'Jméno nesmí přesáhnout délku 35 znaků'
      }else if (values.firstName.length < 3) {
        errors.firstName = 'Jméno musí být alespoň 2 znaky dlouhé'
      }
      if (!values.lastName) {
        errors.lastName = 'Povinné'
      } else if (values.lastName.length > 50) {
        errors.lastName = 'Příjmení nesmí přesáhnout délku 50 znaků'
      }else if (values.lastName.length < 3) {
        errors.lastName = 'Jméno musí být alespoň 2 znaky dlouhé'
      }
      if (!values.email) {
        errors.email = 'Povinné'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Neplatný email'
      }
      if (!values.tel) {
        errors.tel = 'Povinné'
      } else if (values.tel.length < 9) {
        errors.tel = 'Zadejte platné číslo'
      } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/i.test(values.tel)) {
        errors.tel = 'Zadejte platné číslo'
      }
      if (!values.zip) {
        errors.zip = 'Povinné'
      } else if (values.zip.length < 5) {
        errors.zip = 'Zadejte platné PSČ'
      } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{2})$/i.test(values.zip)) {
        errors.zip = 'Zadejte platné PSČ'
      }
      if (!values.captcharesponse) {
        errors.captcharesponse = 'Prosím ověřte že nejste robot.';
      }
    return errors
  }
function renderOptions(options){
  return options.map(item => {
      if(item.id === 0){
        return(
            <option key={item.id} value={''}>{item.name} </option>
        );
      }
      return(
          <option key={item.id} value={item.name}>{item.name}</option>
      );
  })
}
function renderSelect({ input, options, label, meta: { touched, error }}){
    return(
        <>
            <Form.Label>{label}</Form.Label>
            <Form.Control value={options[0]} as={'select'} {...input} size="sm" 
            isInvalid={touched && error}
            isValid={touched && !error}>
                {renderOptions(options)}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
            {error}
            </Form.Control.Feedback>
        </>
    );
 
}

function renderRadio({ label, meta: { touched, error }}){
    return(
        <>
             <Form.Check
          label={label}
          component="input"
            />
          <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </>
    );
}

function renderField({ input, customAS, label, customRows, type, placeholder, meta: { touched, error } }){
 
      return(
          <>
            <Form.Label>{label}</Form.Label>
            <Form.Control  {...input} 
            placeholder={placeholder} 
            type={type}
            as={customAS === null? null : customAS}
            rows={customRows === null? null : customRows}
            isInvalid={touched && error}
            isValid={touched && !error}
            className="shadow-sm"
            />
            <Form.Control.Feedback type="invalid">
                  {error}
              </Form.Control.Feedback>
          </>
      );
  }
var MorgageContactForm = (props) =>{
    const dispatch = useDispatch();

    const { handleSubmit, valid, initialValues, t} = props;
    const appUI = useSelector(state => state.appUI);


    function handleFormSubmit(formValues){
        props.handleFormSubmit(formValues);
    }
    const ucelArray = [
        {id: 0, name: 'Vyberte ůčel..'},
        {id: 1, name: 'Koupě'},
        {id: 2, name: 'Rekonstrukce'},
        {id: 3, name: 'Refinancování'},
        {id: 4, name: 'Vypořádání SJM'},
        {id: 5, name: 'Americká hypotéka'},
        {id: 6, name: 'Nemovitost na pronájem'},
    ]
    const zdrojeArray = [
        {id: 0, name: 'Vyberte původ..'},
        {id: 1, name: 'Vlastní zdroje'},
        {id: 2, name: 'Zástava nemovitostí'},
    
    ]

    const fixaceArray = [
        {id: 0, name: 'Vyberte délku..'},
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
        {id: 6, name: 6},
        {id: 7, name: 7},
        {id: 8, name: 8},
        {id: 9, name: 9},
        {id: 10, name: 10},
        {id: 11, name: 11},
    ]
  
    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <hr />
             
            <div className="mb-3 shadow contact-section">
            <span className="d-flex justify-content-center"><h5 className="fw-light">Kontaktní informace</h5></span>
            <span className="d-flex justify-content-center fw-light small-text-description">(Zadejte adresu nemovitosti)</span>
                <Form.Group className="space-bottom">
                    <Row>
                        <Col>
                            <Field name="firstName" type="text" component={renderField} placeholder="* Jméno"/> 
                        </Col>
                        <Col>
                            <Field name="lastName"  type="text" component={renderField} placeholder="* Příjmení" /> 
                        </Col>
                    </Row>
                </Form.Group>
         
                <Form.Group className="space-bottom">
                
                    <Field name="email" type="email" component={renderField}  placeholder="* E-mail"/>
                </Form.Group>
                <Form.Group className="space-bottom">
                <Form.Group className="space-bottom">
                    <Field name="tel" type="tel" component={renderField}  placeholder="* Telefon: např. 111 222 333"/>
                </Form.Group>
                <Row>
                        <Col>
                    <Field name="city" type="text" component={renderField}  placeholder="Město"/>
                    </Col>
                        <Col>
                        <Field name="zip" type="text" component={renderField}  placeholder="* PSČ"/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="space-bottom">
                
                  <Field name="adress" type="text" component={renderField}  placeholder="* Ulice a č.p."/>
                </Form.Group>
                
                
        </div>
        <Form.Group className="small-space-bottom">
        <CustomCheckBox name="souhlas"  feedback="Bez Vašeho souhlasu požadavek nemůžeme zpracovat." privacyAgreement={true}/>
        </Form.Group>
            <Row className="mb-3">
            <Form.Group as={Col} md="8" className="small-space-bottom">
            <Field name='captcharesponse' component={Captcha}/>
            </Form.Group>
            <Form.Group as={Col} className="d-flex justify-content-end" md="4">
                <Button size="lg" block  type="submit" 
                    variant="outline-primary"
                    className="fw-light shadow-lg next-button border-0 ">
                        Odeslat žádost
                </Button>
            </Form.Group>
            </Row>
        </Form>
    )
}

export default reduxForm({
    form: 'morgageContactForm', // a unique identifier for this form
    validate            // <--- validation function given to redux-form
  })(MorgageContactForm)


  