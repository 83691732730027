import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import { useSelector  } from 'react-redux';
function PageHelmet(props){
    const appUI = useSelector(state => state.appUI);
    console.log(props);
        return(
            <React.Fragment>
                <Helmet>
                    <title>{props.pageTitle} | {appUI.realEstateAgentTexts.personal.helmetName} </title>
                    <meta name="description" content={props.metaDescription} />
                    <meta name="keywords" content={props.metaKeywords} />
                    <meta property="og:image" content={props.ogImage} />
                </Helmet>
            </React.Fragment>
        )
}


export default PageHelmet;
