import React from "react";
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function TabsTwo(props) {
    const appUI = useSelector(state => state.appUI);

    function renderTabNames() {
        return appUI.realEstateAgentTexts.personal.homePage.tabs.map((tab, index) => {
            return (
                <Tab key={index}>{tab.name}</Tab>
            )
        })
    }

    function renderPanelItems(contentArray) {

        return contentArray.map((item, index) => {
            return (
                <li key={index} >
                    <a href={item.href} ><p dangerouslySetInnerHTML={{ __html: item.title }}></p> <span> {item.specification}</span></a>
                    {item.text}
                </li>
            )
        })
    }
    function renderPanelItemsFuck(contentArray) {

        return contentArray.map((item, index) => {
            if(index === 0)
            return (
                <li key={index} style={{ marginBottom: '0px' }}>
                    <a href={item.href} ><p dangerouslySetInnerHTML={{ __html: item.title }}></p> <span> {item.specification}</span></a>
                    {item.text}
                </li>
            )
            else{
                return (
                    <li key={index}>
                       
                        {item.text}
                    </li>
                )
            }
        })
    }
    function renderPanels() {
        return appUI.realEstateAgentTexts.personal.homePage.tabs.map((tab, index) => {
            if (appUI.realEstateAgentTexts.personal.name.includes("Berková") && index === 0) {
                return (
                    <TabPanel key={index}>
                        <div className="single-tab-content">
                            <ul>
                                {renderPanelItemsFuck(tab.content)}
                            </ul>
                        </div>
                    </TabPanel>
                )
            }
            else {
                return (
                    <TabPanel key={index}>
                        <div className="single-tab-content">
                            <ul>
                                {renderPanelItems(tab.content)}
                            </ul>
                        </div>
                    </TabPanel>
                )
            }
        })
    }

    let
        tab2 = "Moje dovednosti",
        tab1 = "Zkušenosti",
        tab3 = "Vzdělání a Certifikace";
    const { tabStyle } = props
    return (
        <div>
            {/* Start Tabs Area */}
            <div className="tabs-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs>
                                <TabList className={`${tabStyle}`}>
                                    {renderTabNames()}
                                </TabList>


                                {renderPanels()}

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Tabs Area */}
        </div>
    )
}



export default TabsTwo;