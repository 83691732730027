import React, {useEffect, Fragment} from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import RealEstateBroker from "../home/RealEstateBroker";
import { useDispatch  } from 'react-redux';

function HomePage(){
    
        return (
            <Fragment>
               <RealEstateBroker />
                
            </Fragment>
        )
}
export default HomePage;

