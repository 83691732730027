import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from '../component/common/ScrollTop';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderSecondary";
import Footer from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";
import './Podminky.css';

class ObchodniPodminky extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Obchodní podmínky' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Všeobecné obchodní podmínky</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner ">
                                        <p className="small-text">
                                        1. Úvod<br/>
1.1 Společnost P4L s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, je poskytovatelem softwaru, IT řešení a marketingu (registrované obchodní značky) a provozuje web www.p4life.cz svým smluvním
partnerům (odběratelům, smluvním poradcům), služby poskytuje za níže uvedených podmínek. Web www.p4life.cz a služby s ním související slouží ke zprostředkování obchodních příležitostí (kontaktů), poptávek a nabídek a zpřístupňuje kontakty za účelem navázání spolupráce pro Uživatele. Poptávkou se rozumí odeslaný strukturovaný požadavek od uživatele s cílem získat obecné informace ohledně hypotečního úvěru (dále jen „hypotéky“) díky propojení (telefonickém i osobním) s Poradci.<br/>
1.2 Společnost P4L s.r.o. poskytuje software a registrovanou obchodní značku a marketingové služby se značkou související. Tyto služby jsou poskytovány odběratelům (smluvním partnerům). Stejně tak celý web je provozován na základě smluvně daného vztahu (případně placených licencí) pro odběratele. Těmito odběrateli (smluvními partnery) jsou společnosti nebo jednotlivé osoby podnikající v oblasti finančního poradenství s oprávněním zprostředkovávat spotřebitelské úvěry (hypotéky) dle zákona č. 257/2016 Sb. o spotřebitelském úvěru, v platném znění (dále jen jako „zákon o spotřebitelském úvěru“). Tyto subjekty jsou držitelé potřebných licencí pro zprostředkovatelskou činnost
a jsou registrovány u České národní banky. Tedy společnost P4L s.r.o. pouze poskytuje software, který spravuje, také doménu www.p4life.cz a poskytuje odběratelům marketingové řešení na míru.
<br/>1.3 Prohlášení k závaznosti informací – není–li výslovně uvedeno jinak, modelové příklady, kalkulace a výpočty ve všech kalkulačkách na tomto webu jsou orientační a nejsou nabídkou k uzavření smlouvy, příslibem k uzavření smlouvy, vyhlášením veřejné soutěže o nejvýhodnější nabídku, veřejnou nabídku ani jakýmkoliv jiným právním jednáním P4L s.r.o., a to bez ohledu na jeho obsah či označení; pokud kterákoliv část tohoto webu vykazuje obsahové
náležitosti jiného právního jednání P4L s.r.o., je výhradně právně nezávazným informativním sdělením, i pokud tak není zvlášť označena.
<br/>1.4 Tyto všeobecné obchodní podmínky (dále jen „VOP“) stanoví základní pravidla, kterými se budou řídit všechny vztahy vzniklé mezi Poskytovatelem a Uživatelem.
                                        </p>
                                        <p className="small-text">
                                        2. Definice Pojmů
                                        <br/>2.1 „Poskytovatel“ znamená společnost P4L s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, je poskytovatelem softwaru provozující webové stránky www.p4life.cz pro svoje odběratele softwaru (smluvní partnery).
                                        <br/>2.2 „Uživatel“ znamená Zájemce, Makléře a jakákoliv jiná osoba využívající služby na Portálu Poskytovatele.
                                        <br/>2.3 „Portál“ znamená webové rozhraní dostupné na adrese www.p4life.cz, které umožňuje sdružovat a srovnávat mezi sebou nabídky a poptávky hypoték a zpřístupňuje kontakty jednotlivých subjektů.
                                        <br/>2.4 „Makléř“ znamená, fyzická osoba podnikající nebo zaměstnanec právnické osoby, zabývající se zprostředkováním koupě/prodeje nemovitých věcí ve prospěch Zájemce.
                                        <br/>2.5 „Poradce“ znamená fyzická osoba podnikající v rámci aktivit P4L s.r.o., IČO: 04534999, sídlo: Korunní 588/4, 120 00 Praha 2 a vybraných smluvních odběratelů, tedy makléřských a poradenských společností, se specializací na
hypoteční úvěry (s oprávněním zprostředkovávat spotřebitelské úvěry).
<br/>2.6 „Zájemce“ znamená koncový zákazník, poptávající informace o hypotečním úvěru nebo žádající o propojení s hypotečním specialistou, jehož údaje poskytl Makléř prostřednictvím Portálu Poradcům, nebo které uvedl do Portálu sám.
<br/>2.7 „Hypotéka“ znamená hypoteční úvěr (spotřebitelský úvěr) nebo úvěr ze stavebního spoření, případně jiný finanční produkt, o kterém poskytuje bližší informace příslušný Poradce specializující se na oblast hypotečních úvěrů (spotřebitelských úvěrů).
                                        </p>
                                        <p className="small-text">
                                        3. Účel Portálu
                                        <br/>3.1 Účelem Portálu je na straně jedné sdružovat Poradce a propojovat se Zájemci. Na straně druhé sdružovat poptávky Zájemců a Makléřů o získání bližších informací ohledně Hypotéky. Účelem Portálu je dále umožnit rychlejší získání kontaktu na osobu poskytující informace ohledně hypotečního úvěru. Veškeré informace publikované na tomto webu Poskytovatelem jsou určeny výhradně ke studijním a informativním účelům a v žádném případě je nelze považovat za investiční doporučení, nebo nabídku ve smyslu příslušných
právních předpisů.
                                        </p>
                                        <p className="small-text">
                                        4. Registrace Uživatele
                                        <br/>4.1 Zájemce osobně nebo prostřednictvím Makléře zadá do formuláře dostupného na Portálu podstatné a osobní údaje pro orientační srovnání Hypoték. Těmito údaji jsou – jméno, příjmení, e-mailová adresa, telefonní číslo, výše úvěru a hodnota nemovitosti.
                                        <br/>4.2 Pro získání výsledku srovnávače se Uživatel musí seznámit s těmito VOP. Vyjádření souhlasu prostřednictvím políčka „souhlasím s VOP“ je nezbytnou podmínkou pro užívání Portálu.
<br/>4.3 Po odeslání požadavku k vyhledání Hypotéky je Uživateli zaslán na uvedenou e-mailovou adresu orientační propočet dle zadaných parametrů Zájemce.
                                        </p>
                                        <p className="small-text">
                                        5. Odpovědnost poskytovatele
                                        <br/>5.1 Poskytovatel neodpovídá za činnost Uživatelů v souvislosti s využíváním služeb Portálu ani za způsob jeho užívání. Poskytovatel nenese odpovědnost za případné zneužití těchto služeb Uživateli, Poradci či třetími osobami. Poskytovatel neodpovídá za žádné škody vzniklé Uživatelům či třetím osobám v důsledku užívání či naopak nemožnosti využívání služeb Portálu nebo v souvislosti s nimi. Poskytovatel neodpovídá za plnění povinností v rámci závazků vzniklých mezi Uživateli a Poradci.

<br/>5.2 Uživatel bere na vědomí, že Poskytovatel objektivně nemůže zaručit stoprocentní funkčnost ani bezchybnost fungování služeb Portálu, a to s ohledem na technickou náročnost procesů, které v rámci Portálu probíhají.
                                            </p>
                                            <p className="small-text">
                                            6. Vyřizování stížností Uživatelů
                                            <br/>6.1 Vyřizování stížností Uživatelů zajišťuje Poskytovatel prostřednictvím
elektronické adresy obchod@p4life.cz.
                                            </p>
                                            <p className="small-text">
                                            7. Ceny a poplatky
                                            <br/>7.1 Využívání všech možností a služeb Portálu Uživatelem je zcela zdarma.
                                            </p>
                                            <p className="small-text">
                                            8. Práva a povinnosti
                                            <br/>8.1 Uživatel prohlašuje že
                                            <br/>– je-li Uživatelem Makléř, prohlašuje, že je zplnomocněn k jednání za Zájemce a byl mu poskytnut souhlas se zpracováním osobních údajů Zájemce pro účely užívání Portálu a zpracování nabídky Hypotéky,
                                            <br/>– je plně způsobilý k právnímu jednání, a to zejména s ohledem na svůj věk,
                                            <br/>– nebyl zbaven způsobilosti k právnímu jednání, ani mu tato nebyla omezena v rozsahu znemožňujícím mu vyslovení souhlasu s těmito VOP,
                                            <br/>– veškeré údaje, které o sobě při registraci poskytl, jsou pravdivé, úplné, přesné a správné,
                                            <br/>– že se seznámil s textem těchto VOP a s těmito souhlasí,
                                            <br/>– na základě uděleného Souhlasu se zpracováním je seznámen se skutečností, že jím poskytnuté údaje může Poskytovatel předat/poskytnout Poradcům,

                                            <br/>– pokud zjistí zneužití svých identifikačních údajů, okamžitě toto sdělí Poskytovateli.
                                            <br/>8.2 Poskytovatel je
                                            <br/>8.3 Poskytovatel prohlašuje, že
                                            <br/>– oprávněn na základě uděleného Souhlasu se zpracováním předat sdělené identifikační údaje Poradci,
                                            <br/>– kdykoliv pozastavit činnost Portálu.
                                            <br/>– není koncovým dodavatelem finančních produktů-Hypoték,
                                            <br/>– poskytuje v rámci Portálu prostor pro získání obecných informací týkajících se tématu Hypoték dle kritérií Zájemce. Tyto informace jsou pouze orientační a slouží výhradně ke studijním účelům. Poskytovatel propojuje Uživatele pro získání bližších informací o úvěrech s Poradci.
                                            <br/>8.4 Bližší specifikace práv a povinností Makléře a Poradce je zahrnuta ve zvlášť uzavíraných smlouvách.
                                            </p>
                                            <p className="small-text">
                                            9. Ochrana osobních údajů
                                            <br/>9.1 Ochrana osobních údajů Zájemce, který je fyzickou osobou, je poskytována v souladu s pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně
osobních údajů (dále jen „Nařízení“).
<br/>9.2 Informace o zpracování osobních údajů společností P4L v souladu
s Nařízením jsou dostupné na webové stránce Poskytovatele www.p4life.cz.
                                            </p>
                                            <p className="small-text">
                                            10. Závěrečná ustanovení
                                            <br/>10.1 Komunikace mezi Poskytovatelem a Uživateli, Poradci a případnými třetími osobami probíhá výhradně prostřednictvím emailu, na emailové adrese Poskytovatele obchod@p4life.cz.
                                            <br/>10.2 Právem rozhodným pro veškeré případné spory vzniklé z tohoto smluvního vztahu je právní řád České republiky.

                                            <br/>10.3 Všechny spory vznikající z této smlouvy a v souvislosti s ní budou rozhodovány s konečnou platností u Rozhodčího soudu při Hospodářské komoře České republiky a Agrární komoře České republiky podle jeho řádu jedním rozhodcem jmenovaným předsedou Rozhodčího soudu.
                                            <br/>10.4 Je-li některé ustanovení VOP neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotčena platnost ostatních ustanovení. Změny
a doplňky VOP vyžadují písemnou formu, přičemž o jejich změně bude objednatel informován prostřednictvím oznámení na Portálu.
Tyto VOP nabývají platnosti a účinnosti dne 25. 5. 2018.
                                            </p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                   {/* Start Contact Area */}
                   <div id="contact" className="fix">
                                                <div className="rn-contact-area ptb--120 bg_color--1">
                                                    <ContactOne />
                                                </div>
                                            </div>
                                        {/* End Contact Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop />
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default ObchodniPodminky;