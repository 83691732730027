//form
export const LOAD = 'LOAD';

//UI ACTION TYPES
//app
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';
export const SET_SHOW_MODAL_LEGAL = 'SET_SHOW_MODAL_LEGAL';
export const SHOW_MORGAGE_ESTIMATE = 'SHOW_MORGAGE_ESTIMATE';
export const SHOW_REAL_ESTATE_VALUATION = 'SHOW_REAL_ESTATE_VALUATION';
export const SUBMIT_MORGAGE_FORM = 'SUBMIT_MORGAGE_FORM';
export const SUBMIT_REAL_ESTATE_VALUATION = 'SUBMIT_REAL_ESTATE_VALUATION';
export const SET_MORGAGE_ESTIMATE_VALUE = 'SET_MORGAGE_ESTIMATE_VALUE';
export const SET_MORGAGE_AMOUNT = 'SET_MORGAGE_AMOUNT';
export const SET_MORGAGE_RESOURCES_AMOUNT = 'SET_MORGAGE_RESOURCES_AMOUNT';
export const SET_MORGAGE_LENGTH = 'SET_MORGAGE_LENGTH';
export const SET_MORGAGE_STEP = 'SET_MORGAGE_STEP';
export const SET_REAL_ESTATE_VALUATION_STEP = 'SET_REAL_ESTATE_VALUATION_STEP';
export const SET_REAL_ESTATE_ESTIMATE = 'SET_REAL_ESTATE_ESTIMATE';
export const GET_REALESTATE_DATA = 'GET_REALESTATE_DATA';
export const SET_REAL_ESTATE_LOADING_ERROR = 'SET_REAL_ESTATE_LOADING_ERROR';
export const SET_REAL_ESTATE_PAGINATION = 'SET_REAL_ESTATE_PAGINATION';
export const SET_ASSETS_LOADED = 'SET_ASSETS_LOADED';

export const SUBMIT_CONTACT_CUSTOMER = 'SUBMIT_CONTACT_CUSTOMER';
export const SUBMIT_CONTACT_COWORKER = 'SUBMIT_CONTACT_COWORKER';
export const SET_SUBMIT_CONTACT_MESSAGE = 'SET_SUBMIT_CONTACT_MESSAGE';
export const SET_SUBMIT_SERVICE_MESSAGE = 'SET_SUBMIT_SERVICE_MESSAGE';
export const SET_SERVICE_SUBMITTING = 'SET_SERVICE_SUBMITTING';
export const SET_MAIN_CONTACT_SUBMITTING = 'SET_MAIN_CONTACT_SUBMITTING';
export const GET_COMMON_TEXTS = 'GET_COMMON_TEXTS';
export const GET_REALESTATE_AGENT_TEXTS = 'GET_REALESTATE_AGENT_TEXTS';

