import React from "react";
import { useSelector  } from 'react-redux';

function BrandTwo(){
    const appUI = useSelector(state => state.appUI);
    return(
        <React.Fragment>
            <ul className="brand-style-2">
            { appUI.realEstateAgentTexts.personal.partners.map((partner , index) => (
                                    <li key={index}>
                                    <a href={partner.link} target="_blank"><img src={partner.imgSRC} alt={partner.imgALT}/></a>
                                </li>
                                ))}
            </ul>
        </React.Fragment>
    )
}
export default BrandTwo;