import React from "react";
import { useSelector  } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "react-router-dom";

function ServiceThree(props){
    const appUI = useSelector(state => state.appUI);
        const {column } = props;
        const ServiceContent = appUI.realEstateAgentTexts.common.services.slice(0 , props.item);
        
        return(
            <React.Fragment>
                 <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                <div className="row d-flex justify-content-center">
                    {ServiceContent.map( (val , i) => (
                       
                        <div className={`${column}`} key={i}>
                            <a href="javascript:;">
                                <div className="service service__style--2">
                                    <div className="icon">
                                       <span dangerouslySetInnerHTML={{__html: val.icon}}></span>
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                
                </ScrollAnimation>
            </React.Fragment>
        )
}
export default ServiceThree;
