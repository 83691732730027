import React, {useState, useEffect} from 'react';
import { animateScroll as scroll } from 'react-scroll'
import './ScrollTop.css';

import { FiChevronUp } from "react-icons/fi";

function ScrollTop(){
    const [show, setShow] = useState(false);

    useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    
    function handleScroll() {
    setShow(window.pageYOffset > 160);
    };

    function scrollToTop() {
        scroll.scrollToTop();
    }

    if(show)
    return(
        <>
         <div className="scroll-top shadow" onClick={scrollToTop}><FiChevronUp /></div>
        </>
    )

    return null;
}

export default ScrollTop;