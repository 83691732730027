import React from "react";
import { useDispatch, useSelector } from 'react-redux';


function RealEstateMainList(props){
        const {column , styevariation } = props;
        const dispatch = useDispatch();
        const appUI = useSelector(state => state.appUI);
        const list = appUI.realEstateList.slice(0 , 3);
        function renderList(){
                return list.map((value , index) =>  {
                    if(value._embedded.hasOwnProperty(['wp:featuredmedia'])){
                        let category = value._embedded['wp:term'][0][0].name;
                        if(value._embedded['wp:term'][0][0].name === 'Byt'){
                            if(value._embedded['wp:term'][1][0].name === 'K prodeji'){
                                category = 'Byt na prodej';
                            }
                            else{
                                category = 'Byt na pronájem';
                            }
                        }
                        return (
                             <div className={`${column}`} key={index}>
                                <div className={`portfolio ${styevariation}`}>
                                    <div className="thumbnail-inner">
                                        <div style={{backgroundImage: `url(${value._embedded['wp:featuredmedia'][0].source_url})`}} className={`thumbnail `}></div>
                                        <div style={{backgroundImage:`url(${value._embedded['wp:featuredmedia'][0].source_url})`}} className={`bg-blr-image `}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{category}</p>
                                            <h4><a href="/portfolio-details">{value.title.rendered}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={value.link} target="_blank">Detail</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else return null;
                    
                });
        }
        return(
            <React.Fragment> 
                {renderList()}
            </React.Fragment>
        )
}
export default RealEstateMainList;