import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';

import { useDispatch, useSelector  } from 'react-redux';
import {getRealEstateAgentTexts, getRealEstateAgentProperties} from './actions/realEstateActions';
import _ from 'lodash';
import LoadingPage from "./component/common/LoadingPage";
import Preload from 'react-preload';

import { useClearCache } from 'react-clear-cache';



const PageDataWrapper = (props) => {
   
    const { emptyCacheStorage } = useClearCache();
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    useEffect(() => {
        const latestVersionOfData = localStorage.getItem('latestVersionOfData');
            if(_.isEmpty(appUI.realEstateAgentTexts)){
                dispatch(getRealEstateAgentTexts(emptyCacheStorage, latestVersionOfData));
            }
            if(!appUI.realEstateList.length && !appUI.realEstateLoadingError && !_.isEmpty(appUI.realEstateAgentTexts)){
                dispatch(getRealEstateAgentProperties());
            }
        
    });
   
   
    if(!_.isEmpty(appUI.realEstateAgentTexts) ){
        var loadingIndicator = (<LoadingPage message="Načítání.." />);
        let homePageImages = appUI.realEstateAgentTexts.personal.homePage.img;
        let images = [homePageImages.aboutMe,homePageImages.contactMe, homePageImages.footerImage,homePageImages.hero ];
        return(
            <>
            <Preload
                loadingIndicator={loadingIndicator}
                images={images}
                autoResolveDelay={8000}
                resolveOnError={true}
                mountChildren={true}
            >
                {props.children}
            </Preload>
            </>
        )
    }
    else{
        return <LoadingPage message="Načítání.." />;
    }
  
}
export default withRouter(PageDataWrapper);