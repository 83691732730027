import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {SET_MORGAGE_STEP, SUBMIT_MORGAGE_FORM} from '../../actions/types';
import {submitMorgageData} from '../../actions/onlineServiceActions';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MorgageForm from './MorgageForm';
import FormCompleted from './FormCompleted';
import MorgageContactForm from './MorgageContactForm';
import Loading from '../common/Loading';

function MorgageEstimate(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);

    function handleMorgageFormSubmit(formValues){
    }
    function handleMorgageContactFormSubmit(formValues){
      
      dispatch({type: SET_MORGAGE_STEP, payload: 3})
      dispatch(submitMorgageData(formValues));
  }
      function renderModalBody(){
        if(appUI.morgageEstimateServiceStep === 1){
          return <MorgageForm handleFormSubmit={handleMorgageFormSubmit}/>;
        }
        else if(appUI.morgageEstimateServiceStep === 2){
          return <MorgageContactForm handleFormSubmit={handleMorgageContactFormSubmit}/>;
        }
        else if(appUI.submittingServiceData){
          return <Loading message="Zpracováváme Váš požadavek.."/>
        }
        else if(appUI.morgageEstimateServiceStep === 3){
          return <FormCompleted />;
        }
      }

    return(
        <>
          <Modal.Body>
          <Container>
          {renderModalBody()}
          
        </Container>
        </Modal.Body>
      
        </>
    );
}

export default MorgageEstimate;