// React Required
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import HomePage from './page-demo/HomePage';


// redux imports
import { Provider,  } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'; // applyMiddleware for reduxThunk, compose for redux devtools
import reduxThunk from 'redux-thunk'; // Used for asynchronous action creators
import reducers from './reducers';

// Element Layout
import ServiceDetails from "./elements/ServiceDetails";
import RealEstatePage from "./elements/RealEstatePage";
import NewsDetail from "./elements/NewsDetail";
import error404 from "./elements/error404";
import ObchodniPodminky from './elements/ObchodniPodminky';


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import OsobniUdaje from './elements/OsobniUdaje';
import PageDataWrapper from './PageDataWrapper';
// setup devtools for debugging redux
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// create redux store from combined reducers
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
    );
    
    
function Root(){
  
        return(
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <PageDataWrapper>
                        <PageScrollTop>
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
                           
                                {/* Element Layout */}
                                <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/nemovitosti`} component={RealEstatePage}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/clanky/:title`} component={NewsDetail}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/obchodni-podminky`} component={ObchodniPodminky}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/osobni-udaje`} component={OsobniUdaje}/>
                                
                                <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/:service`} component={HomePage}/>
                                <Route component={error404}/>

                            </Switch>
                        </PageScrollTop>
                    </PageDataWrapper>
                </BrowserRouter>
            </Provider>
        )
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();