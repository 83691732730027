import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {SET_SHOW_MODAL_LEGAL} from '../../actions/types';

import {BsX, BsArrowLeft} from 'react-icons/bs';
import { BiCalculator } from "react-icons/bi";
import { GoHome } from "react-icons/go";
import { VscLaw } from "react-icons/vsc";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Modal.css';

function LegalModal(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    if(appUI.showLegalModal){
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    }
    else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  
    function renderModalTitle(){
        if(appUI.modalLegalType === 'conditions'){
          return (
            <Row className="justify-content-md-center">
            <Col md="auto"  className="my-auto"><VscLaw size={30}/> </Col>
            <Col md="auto"  className="header-col"><h3 className="fw-light">Všeobecné obchodní podmínky</h3></Col>
            </Row>
          )
        }
        else if(appUI.modalLegalType === 'private'){
          return (
            <Row className="justify-content-md-center">
            <Col md="auto" className="my-auto"><VscLaw size={30}/> </Col>
            <Col md="auto" className="header-col"><h3 className="fw-light">Informace o zpracování osobních údajů</h3></Col>
            </Row>
          )
        }
      }
      
   
    
      function renderModalBody(){
        if(appUI.modalLegalType === 'conditions'){
          return <LegalContantTermsAndServices />;
        }
        else if(appUI.modalLegalType === 'private'){
          return <LegalContentPrivateAgreement />;
        }
      }
    
      function handleCloseButtonClick(){
        dispatch({type: SET_SHOW_MODAL_LEGAL, payload: {show: false, legalType: ''}})
      
      }
      let fullscreen= true;
        return(
            <>
             <Modal
            show={appUI.showLegalModal}
            className="modal-fullscreen-custom"
          >
              
            <Modal.Header  className="text-center " > 
            <Col className="d-flex justify-content-start">
            <Button
                            onClick={() =>  handleCloseButtonClick()}
                            variant="link"
                            className="dark-a "
                    ><BsX size={25}/>
                    </Button>
            </Col>
                <Col md="auto" className="text-center">
                {renderModalTitle()}
                </Col>
                <Col>
              
                </Col>
            </Modal.Header>
            <Modal.Body className="legal-body">
                {renderModalBody()}
            </Modal.Body>
            
          </Modal>
        </>
    )
}

export default LegalModal;

const LegalContentPrivateAgreement = () => <>
   <p className="small-text">
                                        Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2,zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264 (dále jen „P4L“), jakožto poskytovatel software, IT řešení a marketingu a provozovatel webového portálu www.p4life.cz a www.p4life-rk.cz,při správě a zpracování osobních údajů svých klientů a spolupracujících osob plně respektuje zásady jejich ochrany a v souladu s platnými právními předpisy níže informuje subjekty osobních údajů o jejich právech, důvodech zpracování a další podstatné informace týkající se zpracování osobních údajů.

<br/><br/>Společnost P4L, s.r.o. se v plném rozsahu řídí pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně osobních údajů (dále jen „Nařízení“) a v souladu s tímto Nařízením přísně dodržuje všechny nastavené postupy.

<br/><br/>Pokud se jakákoli část tohoto dokumentu bude zdát nesrozumitelná nebo vyplyne-li potřeba dalšího podrobnějšího vysvětlení některé problematiky související se zpracováním osobních údajů, je možné obrátit se na naši emailovou adresu:obchod@p4life.cz.
                                        </p>
                                       
                                        <p className="small-text">
Obsah:

<br/><br/>1. Správce osobních údajů a jeho identifikace – kdo to je.
<br/>2. Rozsah zpracování osobních údajů.
<br/>3. Doba, po kterou jsou osobní údaje zpracovány.
<br/>4. Kdo má k osobním údajům přístup, komu jsou poskytovány. 
<br/>5. Automatizované zpracování osobních údajů.
<br/>6. Jaká jsou práva subjektu údajů.
<br/>7. Uplatnění práv subjektů údajů – klientů společnosti P4L.8. Souhlas se zpracováním osobních údajů.

</p>
<p className="small-text">
1. Správce osobních údajů a jeho identifikace – kdo to je<br/><br/>

Správcem je osoba, která disponuje s osobními údaji subjektu údajů a zároveň určuje účel a prostředky jejich zpracování. Společnost P4L je správcem osobních údajů, jelikož shromažďuje osobní údaje zájemců o porovnání nabídek hypoték na základě zadaných kritérií a dále pro tyto zájemce zajišťuje možnost zpracování hypoteční analýzy hypotečním specialistou s cílem rychlého a efektivního vyřízení žádosti o hypotéku.

<br/><br/>Kontaktní údaje a totožnost správce:

<br/><br/>Společnost P4L, s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, e-mail: obchod@p4life.cz.

<br/><br/>Pověřenec pro ochranu osobních údajů nebyl u Správce jmenován. Správce nemá zástupce pro plnění povinností ve smyslu Nařízení.
</p>
<p className="small-text">
2. Rozsah a kategorie zpracování osobních údajů.

<br/><br/>Společnost P4L zpracovává pouze ty osobní údaje, které jsou nezbytné pro řádnéposkytování služeb, plnění zákonných povinností a též k ochraně zájmů společnosti P4L. Shromažďujeme osobní údaje zájemců o naše služby nebo potenciálních zájemců, kteří nám ke zpracování osobních údajů a k oslovení udělili souhlas.

<br/><br/>Kategorie osobních údajů, které společnost P4L zpracovává:

<br/>a) identifikační údaje – jméno, příjmení, datum narození, adresa bydliště, podpis,u podnikatelských subjektů IČ a adresa sídla.

<br/>b) kontaktní údaje – e-mailová adresa a telefonní číslo, adresa místa bydliště – jméno, příjmení, datum narození, adresa bydliště, podpis, u podnikatelských subjektů IČ a adresa sídla.

<br/>c) sociodemografické údaje (věk, příjmy, splátky úvěru, počet dětí) – tyto údaje společnost P4L zpracovává pouze v případě, kdy si subjekt údajů sám dobrovolně


zvolí možnost rozšířených kritérií pro srovnání hypotéky na webovém portáluwww.p4life.cz a www.p4life-rk.cz, poskytnutí těchto údajů není povinné a záleží pouze na volbě subjektu údajů, zda má zájem o podrobnější výsledek srovnání hypotéky.

<br/><br/>Zpracování osobních údajů bez nutnosti udělení souhlasu:

<br/><br/>a) pro účely smluvního plnění

<br/>– poskytnutí osobních údajů pro účely srovnání a kalkulaci hypotéky na základě zadaných kritérií zájemce je smluvním požadavkem společnosti P4L.

<br/><br/>b) zpracování na základě plnění zákonných povinností

<br/>– společnost P4L zpracovává také osobní údaje, pokud jí tuto povinnost přikazuje zákon.

<br/><br/>c) zpracování na základě oprávněných zájmů společnosti P4L 
<br/>– zejména pro účely obhajoby právních nároků společnosti P4L

Zpracování osobních údajů s udělením souhlasu zájemce <br/>– za účelem nabídky finančního produktu – hypotéky:
<br/>– udělení souhlasu zájemcem je dobrovolné, zároveň však nezbytné, aby jej mohl dále kontaktovat hypoteční specialista (Poradce) za účelem zpracování individuální nabídky, hypoteční analýzy a informování zájemce o finančníchproduktech – hypotékách dle kritérií zadaných zájemcem do srovnávače/kalkulačky hypoték na webovém portálu www.p4life.cz a www.p4life- rk.cz.

<br/>– na základě uděleného souhlasu pro účely nabídky finančního produktu –hypotéky může být zájemce kontaktován formou e-mailové zprávy nebo telefonicky, další komunikace probíhá dle preferencí zájemce.

<br/>– osobní údaje mohou být za účelem zpracování nabídky hypotéky poskytnuty hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se zájemcem finanční služby,



<br/>– bez uděleného souhlasu nemůže být zájemce informován ani kontaktován s nabídkou individuálního hypotečního produktu, nelze s ním o možnostech sjednání hypotéky či jiného finančního produktu jednat, nabídka finančních produktů nemůže být více zpřesňována,

<br/>– zájemce může udělený souhlas kdykoli odvolat a to písemně na adresuobchod@p4life.cz, písemně dopisem na korespondenční adresu společnosti P4L– Komenského 446/6, Jablonec nad Nisou, 466 01.
</p>
<p className="small-text">
3.Doba, po kterou jsou osobní údaje zpracovány.
<br/><br/>Osobní údaje klientů společnost P4L zpracovává po dobu, po kterou svým klientům poskytuje své služby nebo po dobu trvání smlouvy. Po ukončení smluvního vztahu a po vypořádání veškerých závazků z takové smlouvy plynoucích a jinak s ní souvisejících uchovává společnost P4L osobní údaje klientů po dobu nezbytně nutnou, která je vyžadována dle příslušných právních předpisů, např. dle zákona o účetnictví, zákona o archivnictví a evidenci, zákona o dani z přidané hodnoty, atd. a dle obecných i speciálních promlčecích lhůt.U udělených souhlasů se zpracováním pro účely nabídky finančního produktu je doba uchování osobních údajů 3 roky od udělení souhlasu.Společnost P4L se při uchování osobních údajů řídí zásadou minimalizace údajů, v okamžiku, kdy odpadne účel zpracování osobních údajů nebo uplyne doba, po kterou je společnost P4L povinna osobní údaje uchovávat, osobní údaje jsou zcela vymazány ze systémů, případně vhodným způsobem anonymizovány. Osobní údaje tak nikdy nejsou zpracovávány nebo uchovávány déle, než je nezbytně nutné.
</p>
<p className="small-text">
4.Kdo má k osobním údajům přístup, komu jsou poskytovány.
<br/><br/>Osobní údaje klientů jsou zpřístupněny společnosti P4L jakožto jejich správci, dálepověřeným zaměstnancům společnosti P4L a smluvním zpracovatelům, se kterými má společnost P4L uzavřenou Smlouvu o zpracování osobních údajů, na základě které jsou tito smluvní zpracovatelé povinni osobní údaje zpracovávat v souladu s Nařízením. Smluvními zpracovateli jsou zejména hypoteční specialisté, tj. Poradci, poskytovatelé IT služeb, účetní a daňoví poradci.



<br/>Osobní údaje zpracovávané na základě uděleného souhlasu za účelem nabídky finančního produktu mohou být v souladu s uděleným souhlasemposkytnuty/předány jinému správci, a to konkrétnímu hypotečnímu specialistovi (dále jen „Poradce“), který má se společností P4L uzavřenou Smlouvu o spolupráci a dle zákona č. 257/2016 Sb., zákon o spotřebitelském úvěru, je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

<br/>Společnost P4L svým klientům garantuje, že osobní údaje, které zpracovává na základě uděleného souhlasu za účelem nabídky finančního produktu, nejsou v žádném případě předávány žádným externím call centrům ani marketingovýmspolečnostem zajišťujícím nejrůznější marketingové kampaně, vždy se jedná o konkrétní osobu hypotečního specialisty, která je smluvním partnerem společnosti P4L.
</p>
<p className="small-text">
5.Automatizované zpracování osobních údajů.
<br/><br/>Součástí poskytování služeb společnosti P4L je i automatizované zpracování osobních údajů. Při automatizovaném zpracování osobních údajů jsou používány automatické informační systémy, např. elektronické kalkulačky, software, IT aplikace. Cílem automatizovaného zpracování osobních údajů je rychléa individuální poskytnutí služby dle specifických požadavků a potřeb klientů.
</p>
<p className="small-text">
6.Jaká jsou práva subjektu údajů.
<br/><br/>a) Právo na informace týkající se zpracování osobních údajůKaždý subjekt zpracování osobních údajů má právo znát totožnost a kontaktní údaje na správce osobních údajů, jeho zástupce i na případné pověřence pro ochranu osobních údajů, dále má právo znát účel a právní základ zpracování osobních údajů, právo znát příjemce nebo kategorie příjemců osobních údajů, informace o předání osobních údajů do třetích zemí, dobu uložení osobních údajů, informaci o právu na odvolání souhlasu a o dalších právech subjektu údajů, možnost podat stížnost u Úřadu na ochranu osobních údajů, informaci, zda je poskytování osobních údajů zákonný či smluvní požadavek, informování o automatizovaném rozhodování a profilování.



<br/><br/>b) Právo na přístup k osobním údajům

<br/>Právo klienta požadovat od společnosti P4L, jakožto správce, potvrzení, zda jeho osobní údaje jsou či nejsou zpracovávány. Pokud zpracovávány jsou, tak máklient, jakožto subjekt údajů, právo získat přístup k těmto osobním údajům a k informacím o účelu jejich zpracování, kategoriích dotčených osobních údajů, příjemcích nebo kategoriích příjemců, kterým jsou, byly nebo mají být osobní údaje zpřístupněny, dobu zpracování osobních údajů, existence práva požadovat opravu, výmaz, omezení a možnosti vznést námitku proti zpracování, informace o právu podat stížnost u Úřadu pro ochranu osobních údajů, informace o zdroji osobních údajů, informace, zda dochází k automatizovanému rozhodování a profilování osobních údajů, informace o zárukách při předávání osobních údajů do třetích zemí nebo mezinárodním organizacím.

<br/>Subjekt údajů má právo požadovat od společnosti P4L kopie zpracovávaných osobních údajů. Při opakovaných a zjevně nepřiměřených žádostech je společnostP4L oprávněna účtovat klientům přiměřený poplatek na úhradu administrativních nákladů za pořízení kopie.

<br/>Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na adresu sídla nebo na e-mailovou adresu společnosti P4L.

<br/><br/>c) Právo na opravu osobních údajů

<br/>Subjekt údajů má právo žádat, aby společnost P4L bez zbytečného odkladu opravila nepřesné a doplnila neúplné osobní údaje, které se jej týkají. Základem uplatnění tohoto práva je sdělení skutečnosti vedoucí k opravě či doplnění společnosti P4L.

<br/><br/>d) Právo na výmaz osobních údajů

<br/>V případech, kdy pominul účel zpracování osobních údajů, byl odvolán souhlas ke zpracování osobních údajů a v dalších případech, které jsou podrobně uvedeny v čl. 17 odst. 1 písm. a) až f) Nařízení, je společnost P4L na základě Vaší žádosti povinna osobní údaje žádajícího klienta smazat. Pokud však není splněna zákonná podmínka pro výmaz, má společnost P4L právo si osobní údajeponechat. Uplatnění tohoto práva mohou subjekty údajů uplatnit kromě zaslání písemné žádosti na korespondenční adresu společnosti P4L – Komenského 446/6,Jablonec nad Nisou, 466 01 nebo na e-mailovou adresu společnosti P4L.



<br/><br/>e) Právo na omezení zpracování osobních údajů

<br/>Na žádost klienta je společnost P4L povinna omezit zpracování jeho osobních údajů jen na nejnutnější zákonné důvody, pokud jsou splněny podmínky dle čl. 18 Nařízení. V obecné rovině lze toto právo uplatnit v případech, kdy není jasné, zda a kdy se budou muset osobní údaje odstranit.

<br/><br/>f) Právo vznesení námitky proti zpracování osobních údajů

<br/>V případech, kdy klient dojde ke zjištění, že společnost P4L zpracovává jeho osobní údaje v rozporu se zákonem nebo v rozporu s ochranou soukromého a osobního života klienta, má právo obrátit se přímo na společnost P4L s toutonámitkou, aby došlo k co nejrychlejšímu odstranění závadného stavu nebo k dostatečnému zdůvodnění nastalé situace. Námitky lze vznášet i proti automatizovanému rozhodování/profilování.

<br/><br/>g) Právo na přenositelnost údajů

<br/>Klient může požadovat, aby jeho osobní údaje, které společnosti P4L udělil, byly předány jinému správci dle požadavku klienta. Osobní údaje budou přeneseny v běžně používaném odpovídajícím formátu, nebude-li požadavku klienta bránit zákonná či jiná překážka.

<br/><br/>h) Právo podat podnět/stížnost na Úřad pro ochranu osobních údajů

<br/>S jakýmkoli podnětem či stížností týkající se zpracování osobních údajů se klient může obrátit přímo na Úřad pro ochranu osobních údajů, jakožto na dozorový orgán.
</p>
<p className="small-text">
7. Uplatnění práv subjektů údajů – klientů společnosti P4L.

<br/><br/>Jednotlivá práva uvedená v čl. 6 tohoto informačního dokumentu mohou subjekty údajů uplatnit u správce, společnosti P4L buď e-mailovou zprávou na adresu:obchod@p4life.cz nebo písemně dopisem na adresu společnosti P4L -Komenského 446/6, Jablonec nad Nisou, 466 01.

<br/><br/>Žádosti subjektů údajů je společnost P4L povinna vyřešit nejpozději ve lhůtě 1 měsíce ode dne přijetí žádosti. V odůvodněných případech zejména s ohledem na rozsah žádosti je společnost P4L oprávněna lhůtu



k vyjádření prodloužit na 2 měsíce, o nutnosti prodloužení společnost P4L subjektúdajů včas informuje.

<br/><br/>Dokument je pravidelně aktualizován, datum poslední aktualizace 25. 5. 2018.

<br/><br/>Souhlas se zpracováním osobních údajů

<br/>Tímto uděluji společnosti P4L s. r. o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, zastoupena jednatelem Lukášem Berkou, email: obchod@p4life.cz (dále jen „Správce“) souhlas se zpracováním osobních údajův rozsahu:

<br/>- jméno a příjmení
<br/>- emailová adresa
<br/>- telefonní číslo
<br/>- adresa trvalého bydliště
<br/>- věk žadatele
<br/>- měsíční příjem
<br/>- měsíční splátky úvěru
<br/>- členové domácnosti
<br/>- počet žadatelů
<br/>- platební historie

<br/><br/>(dále jen „Osobní údaje“)

<br/>za účelem:

<br/><br/>Osobní údaje budou použity za účelem zasílání informací, produktů, služeb, novinek a aktualit týkajících se finančního trhu tj. newsletterů a dále ke zpracování nabídky finančních produktů dle kritérií zadaných subjektemúdajů do srovnávače / kalkulačky hypoték na webovém portálu Správcehttp://www.p4life.cz a www.p4life-rk.cz, ke kontaktování subjektu údajůe-mailem či telefonicky a k dalšímu jednání o finančních produktech s hypotečním specialistou.

<br/><br/>Osobní údaje jsou zpracovávány 3 roky a může na základě jejich zpracování docházet k automatizovanému zpracování či profilování.



<br/><br/>Osobní údaje mohou být za účelem zpracování nabídky finančního produktu poskytnuty / předány jinému správci, a to hypotečnímu specialistovi (dále jen „Poradce“), který má se Správcem uzavřenou Smlouvu o spolupráci a je oprávněn poskytovat a sjednávat se subjektem údajů finanční služby.

<br/><br/>Dále beru na vědomí, že tentosouhlas uděluji svobodně a dobrovolně a můžu jej kdykoliv odvolat formou písemné informace zaslané na adresuSprávce nebo na e-mailovou adresu obchod@p4life.cz, a to i před uplynutím doby, na níž jsem souhlas udělil/(a). Odvoláním tohoto souhlasu není dotčena zákonnost zpracování osobních údajů v době před odvoláním souhlasu.

<br/><br/>Více informací o zpracování mých osobních údajů je uvedeno v informačním dokumentu, který je k dispozici na webové adrese www.p4life.cz a www.p4life- rk.cz.

<br/><br/>V případě dotazů týkajících se zpracování mých osobních údajů se mohu obrátit na správce osobních údajů, a to prostřednictvím emailové adresy obchod@p4life.cz.
</p>
</>;
const LegalContantTermsAndServices = () => <>
   <p className="small-text">
                                        1. Úvod<br/>
1.1 Společnost P4L s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, je poskytovatelem softwaru, IT řešení a marketingu (registrované obchodní značky) a provozuje web www.p4life.cz svým smluvním
partnerům (odběratelům, smluvním poradcům), služby poskytuje za níže uvedených podmínek. Web www.p4life.cz a služby s ním související slouží ke zprostředkování obchodních příležitostí (kontaktů), poptávek a nabídek a zpřístupňuje kontakty za účelem navázání spolupráce pro Uživatele. Poptávkou se rozumí odeslaný strukturovaný požadavek od uživatele s cílem získat obecné informace ohledně hypotečního úvěru (dále jen „hypotéky“) díky propojení (telefonickém i osobním) s Poradci.<br/>
1.2 Společnost P4L s.r.o. poskytuje software a registrovanou obchodní značku a marketingové služby se značkou související. Tyto služby jsou poskytovány odběratelům (smluvním partnerům). Stejně tak celý web je provozován na základě smluvně daného vztahu (případně placených licencí) pro odběratele. Těmito odběrateli (smluvními partnery) jsou společnosti nebo jednotlivé osoby podnikající v oblasti finančního poradenství s oprávněním zprostředkovávat spotřebitelské úvěry (hypotéky) dle zákona č. 257/2016 Sb. o spotřebitelském úvěru, v platném znění (dále jen jako „zákon o spotřebitelském úvěru“). Tyto subjekty jsou držitelé potřebných licencí pro zprostředkovatelskou činnost
a jsou registrovány u České národní banky. Tedy společnost P4L s.r.o. pouze poskytuje software, který spravuje, také doménu www.p4life.cz a poskytuje odběratelům marketingové řešení na míru.
<br/>1.3 Prohlášení k závaznosti informací – není–li výslovně uvedeno jinak, modelové příklady, kalkulace a výpočty ve všech kalkulačkách na tomto webu jsou orientační a nejsou nabídkou k uzavření smlouvy, příslibem k uzavření smlouvy, vyhlášením veřejné soutěže o nejvýhodnější nabídku, veřejnou nabídku ani jakýmkoliv jiným právním jednáním P4L s.r.o., a to bez ohledu na jeho obsah či označení; pokud kterákoliv část tohoto webu vykazuje obsahové
náležitosti jiného právního jednání P4L s.r.o., je výhradně právně nezávazným informativním sdělením, i pokud tak není zvlášť označena.
<br/>1.4 Tyto všeobecné obchodní podmínky (dále jen „VOP“) stanoví základní pravidla, kterými se budou řídit všechny vztahy vzniklé mezi Poskytovatelem a Uživatelem.
                                        </p>
                                        <p className="small-text">
                                        2. Definice Pojmů
                                        <br/>2.1 „Poskytovatel“ znamená společnost P4L s.r.o., IČ 04534999, se sídlem Korunní 588/4, 120 00 Praha 2, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 249264, je poskytovatelem softwaru provozující webové stránky www.p4life.cz pro svoje odběratele softwaru (smluvní partnery).
                                        <br/>2.2 „Uživatel“ znamená Zájemce, Makléře a jakákoliv jiná osoba využívající služby na Portálu Poskytovatele.
                                        <br/>2.3 „Portál“ znamená webové rozhraní dostupné na adrese www.p4life.cz, které umožňuje sdružovat a srovnávat mezi sebou nabídky a poptávky hypoték a zpřístupňuje kontakty jednotlivých subjektů.
                                        <br/>2.4 „Makléř“ znamená, fyzická osoba podnikající nebo zaměstnanec právnické osoby, zabývající se zprostředkováním koupě/prodeje nemovitých věcí ve prospěch Zájemce.
                                        <br/>2.5 „Poradce“ znamená fyzická osoba podnikající v rámci aktivit P4L s.r.o., IČO: 04534999, sídlo: Korunní 588/4, 120 00 Praha 2 a vybraných smluvních odběratelů, tedy makléřských a poradenských společností, se specializací na
hypoteční úvěry (s oprávněním zprostředkovávat spotřebitelské úvěry).
<br/>2.6 „Zájemce“ znamená koncový zákazník, poptávající informace o hypotečním úvěru nebo žádající o propojení s hypotečním specialistou, jehož údaje poskytl Makléř prostřednictvím Portálu Poradcům, nebo které uvedl do Portálu sám.
<br/>2.7 „Hypotéka“ znamená hypoteční úvěr (spotřebitelský úvěr) nebo úvěr ze stavebního spoření, případně jiný finanční produkt, o kterém poskytuje bližší informace příslušný Poradce specializující se na oblast hypotečních úvěrů (spotřebitelských úvěrů).
                                        </p>
                                        <p className="small-text">
                                        3. Účel Portálu
                                        <br/>3.1 Účelem Portálu je na straně jedné sdružovat Poradce a propojovat se Zájemci. Na straně druhé sdružovat poptávky Zájemců a Makléřů o získání bližších informací ohledně Hypotéky. Účelem Portálu je dále umožnit rychlejší získání kontaktu na osobu poskytující informace ohledně hypotečního úvěru. Veškeré informace publikované na tomto webu Poskytovatelem jsou určeny výhradně ke studijním a informativním účelům a v žádném případě je nelze považovat za investiční doporučení, nebo nabídku ve smyslu příslušných
právních předpisů.
                                        </p>
                                        <p className="small-text">
                                        4. Registrace Uživatele
                                        <br/>4.1 Zájemce osobně nebo prostřednictvím Makléře zadá do formuláře dostupného na Portálu podstatné a osobní údaje pro orientační srovnání Hypoték. Těmito údaji jsou – jméno, příjmení, e-mailová adresa, telefonní číslo, výše úvěru a hodnota nemovitosti.
                                        <br/>4.2 Pro získání výsledku srovnávače se Uživatel musí seznámit s těmito VOP. Vyjádření souhlasu prostřednictvím políčka „souhlasím s VOP“ je nezbytnou podmínkou pro užívání Portálu.
<br/>4.3 Po odeslání požadavku k vyhledání Hypotéky je Uživateli zaslán na uvedenou e-mailovou adresu orientační propočet dle zadaných parametrů Zájemce.
                                        </p>
                                        <p className="small-text">
                                        5. Odpovědnost poskytovatele
                                        <br/>5.1 Poskytovatel neodpovídá za činnost Uživatelů v souvislosti s využíváním služeb Portálu ani za způsob jeho užívání. Poskytovatel nenese odpovědnost za případné zneužití těchto služeb Uživateli, Poradci či třetími osobami. Poskytovatel neodpovídá za žádné škody vzniklé Uživatelům či třetím osobám v důsledku užívání či naopak nemožnosti využívání služeb Portálu nebo v souvislosti s nimi. Poskytovatel neodpovídá za plnění povinností v rámci závazků vzniklých mezi Uživateli a Poradci.

<br/>5.2 Uživatel bere na vědomí, že Poskytovatel objektivně nemůže zaručit stoprocentní funkčnost ani bezchybnost fungování služeb Portálu, a to s ohledem na technickou náročnost procesů, které v rámci Portálu probíhají.
                                            </p>
                                            <p className="small-text">
                                            6. Vyřizování stížností Uživatelů
                                            <br/>6.1 Vyřizování stížností Uživatelů zajišťuje Poskytovatel prostřednictvím
elektronické adresy obchod@p4life.cz.
                                            </p>
                                            <p className="small-text">
                                            7. Ceny a poplatky
                                            <br/>7.1 Využívání všech možností a služeb Portálu Uživatelem je zcela zdarma.
                                            </p>
                                            <p className="small-text">
                                            8. Práva a povinnosti
                                            <br/>8.1 Uživatel prohlašuje že
                                            <br/>– je-li Uživatelem Makléř, prohlašuje, že je zplnomocněn k jednání za Zájemce a byl mu poskytnut souhlas se zpracováním osobních údajů Zájemce pro účely užívání Portálu a zpracování nabídky Hypotéky,
                                            <br/>– je plně způsobilý k právnímu jednání, a to zejména s ohledem na svůj věk,
                                            <br/>– nebyl zbaven způsobilosti k právnímu jednání, ani mu tato nebyla omezena v rozsahu znemožňujícím mu vyslovení souhlasu s těmito VOP,
                                            <br/>– veškeré údaje, které o sobě při registraci poskytl, jsou pravdivé, úplné, přesné a správné,
                                            <br/>– že se seznámil s textem těchto VOP a s těmito souhlasí,
                                            <br/>– na základě uděleného Souhlasu se zpracováním je seznámen se skutečností, že jím poskytnuté údaje může Poskytovatel předat/poskytnout Poradcům,

                                            <br/>– pokud zjistí zneužití svých identifikačních údajů, okamžitě toto sdělí Poskytovateli.
                                            <br/>8.2 Poskytovatel je
                                            <br/>8.3 Poskytovatel prohlašuje, že
                                            <br/>– oprávněn na základě uděleného Souhlasu se zpracováním předat sdělené identifikační údaje Poradci,
                                            <br/>– kdykoliv pozastavit činnost Portálu.
                                            <br/>– není koncovým dodavatelem finančních produktů-Hypoték,
                                            <br/>– poskytuje v rámci Portálu prostor pro získání obecných informací týkajících se tématu Hypoték dle kritérií Zájemce. Tyto informace jsou pouze orientační a slouží výhradně ke studijním účelům. Poskytovatel propojuje Uživatele pro získání bližších informací o úvěrech s Poradci.
                                            <br/>8.4 Bližší specifikace práv a povinností Makléře a Poradce je zahrnuta ve zvlášť uzavíraných smlouvách.
                                            </p>
                                            <p className="small-text">
                                            9. Ochrana osobních údajů
                                            <br/>9.1 Ochrana osobních údajů Zájemce, který je fyzickou osobou, je poskytována v souladu s pravidly stanovenými Nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, obecného nařízení o ochraně
osobních údajů (dále jen „Nařízení“).
<br/>9.2 Informace o zpracování osobních údajů společností P4L v souladu
s Nařízením jsou dostupné na webové stránce Poskytovatele www.p4life.cz.
                                            </p>
                                            <p className="small-text">
                                            10. Závěrečná ustanovení
                                            <br/>10.1 Komunikace mezi Poskytovatelem a Uživateli, Poradci a případnými třetími osobami probíhá výhradně prostřednictvím emailu, na emailové adrese Poskytovatele obchod@p4life.cz.
                                            <br/>10.2 Právem rozhodným pro veškeré případné spory vzniklé z tohoto smluvního vztahu je právní řád České republiky.

                                            <br/>10.3 Všechny spory vznikající z této smlouvy a v souvislosti s ní budou rozhodovány s konečnou platností u Rozhodčího soudu při Hospodářské komoře České republiky a Agrární komoře České republiky podle jeho řádu jedním rozhodcem jmenovaným předsedou Rozhodčího soudu.
                                            <br/>10.4 Je-li některé ustanovení VOP neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotčena platnost ostatních ustanovení. Změny
a doplňky VOP vyžadují písemnou formu, přičemž o jejich změně bude objednatel informován prostřednictvím oznámení na Portálu.
Tyto VOP nabývají platnosti a účinnosti dne 25. 5. 2018.
                                            </p>
</>;