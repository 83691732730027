import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BsPerson, BsPeople} from "react-icons/bs";
import { GrUserFemale } from "react-icons/gr";
import './Testimonial.css';
import {Link} from 'react-scroll';
import { useSelector  } from 'react-redux';

function Testimonial(){
    const appUI = useSelector(state => state.appUI);
        const [testimonials, setTestimonials] = useState(appUI.realEstateAgentTexts.personal.testimonials)
        function renderAvatar(testimonial){
            if(testimonial.gender === 'male'){
                return <BsPerson size={70} className="border rounded"/>;
            }
            else if(testimonial.gender === 'female'){
                return <GrUserFemale size={70} className="border rounded padded-icon"/>;
            }
            else{
                return <BsPeople size={70} className="border rounded padded-icon"/>;
            }
        }
        function openTestimonial(index){
            let newTestimonials = [...testimonials];
            newTestimonials[index].open = true;
            setTestimonials(newTestimonials);
        }
        function closeTestimonial(index){
            let newTestimonials = [...testimonials];
            newTestimonials[index].open = false;
            setTestimonials(newTestimonials);
        }
        function renderTabPanelsQuote() {
            return testimonials.map((testimonial, index) => {
                function renderQuoteText(){
                    if(testimonial.text.length <= 220){
                       return <p key={index}>{testimonial.text}</p>;
                    }
                    else if(!testimonial.open){
                        let slicedQuote = testimonial.text.slice(0, 220);
                        return <p key={index}>{slicedQuote} <br/><span className="fake-link" onClick={() => openTestimonial(index)}>číst dále...</span></p>;
                    }
                    else{
                        return <p key={index} className="long-paragraph">{testimonial.text} <br/><Link to="testimonial"  offset={-100} spy={true} onClick={() => closeTestimonial(index)}> <span className="fake-link">schovat text...</span></Link></p>;
                    }
        
                }
                return(
                    <TabPanel key={index}>
                    <div className="rn-testimonial-content text-center">
                        <div className="inner">
                            {renderQuoteText()}
                        </div>
                        <div className="author-info">
                            <h6><span>{testimonial.name} </span> {testimonial.position}</h6>
                        </div>
                    </div>
                </TabPanel>
                );
            });
        }
        // TODO: render images of persons
        function renderTabAvatar() {
            return testimonials.map((testimonial, index) => {
                // Pokud není fotka tak render avatara
                if(testimonial.imgSRC === ''){
                    return(
                        <Tab key={index}>
                            <div className="testimonial-thumbnai">
                                    <div className="thumb">
                                    {renderAvatar(testimonial)}
                                    {/*<img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>*/}
                                </div>
                            </div>
                        </Tab>
                    );
                }
                else{
                    return(
                        <Tab>
                            <div className="testimonial-thumbnai">
                                    <div className="thumb">
                                    
                                    <img src={testimonial.imgSRC} alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                    );
                }
            });
        }
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            {renderTabPanelsQuote()}

                            <TabList className="testimonial-thumb-wrapper">
                                {renderTabAvatar()}
                            </TabList>
                            
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
}
export default Testimonial;