import React from 'react';
import './Loading.css';
import { WaveSpinner    } from "react-spinners-kit";
import SiteContent from '../../SiteContent';

function LoadingPage({message}){
   

    return(
        <div  className="loading-page-wrapper ">
           <div className="content-div">
            <div className="d-flex justify-content-center text-center "><h1 className="fw-light text-center display-1">{SiteContent.name}</h1></div>
            <div className="text-center"><h4 className="fw-light  ">{SiteContent.type}</h4></div>
            <br/>
                <div className="d-flex justify-content-center ">
                    <WaveSpinner size={100} color="#1f1f25" loading={true} />
                </div>
            </div>
        </div>
    )
}

export default LoadingPage;