import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { isValid } from 'redux-form';

function Captcha({
    input: { value, onChange, ...input },
    meta: { touched, error },
    }){
    let isInvalid = '';

    if(touched && error ){
    isInvalid = 'is-invalid';
    } else if(touched && !error){
    isInvalid = '';

    }
    console.log(isInvalid);
return(
    <div className={`input-group has-validation`}>   
     <input style={{display: 'none'}} className={`form-control ${isInvalid}`} >
      </input>
      <ReCAPTCHA
        sitekey='6LdG8BAdAAAAADBurhQ_3Vg8obMNaw3QErrPJ6FZ'
        onChange={onChange}
      />
        <div className="invalid-feedback">
            Prosím ověřte že nejste robot
        </div>
      </div>
  );
}
  export default Captcha;