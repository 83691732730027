import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import Pagination from "./common/Pagination";
import ContactOne from "./contact/ContactOne";
import RealEstateList from "./realestate/RealEstateList";
import ScrollToTop from '../component/common/ScrollTop';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderSecondary";
import Footer from "../component/footer/FooterTwo";
import { useDispatch, useSelector  } from 'react-redux';
import {getRealEstateAgentProperties} from '../actions/realEstateActions';
import Loading from "../component/common/Loading";

function RealEstatePage (){
    const appUI = useSelector(state => state.appUI);
    const dispatch = useDispatch();
  


    function renderRealEstate(){
        if(!appUI.realEstateList.length && !appUI.realEstateList.realEstateLoadingError)
        {
            return(
                <>
                   <Loading message="Načítání nemovitostí.."/>
                </>
            )
        }
        else if(appUI.realEstateList.realEstateLoadingError){
            return(
                <>
                   
                            <div className="row d-flex justify-content-center text-center">
                                <h1 className="fw-light">Došlo k hybě při načítání nemovitostí. Zkontrolujte prosím vaše připojení k internetu.</h1>
                            </div>
                          
                </>
            )
        }
        else{
            return(
                <>
                   <RealEstateList /> 
                </>
            )
        }
    }
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Nemovitosti' />

                <Header homeLink="/" logo="symbol-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Všechny nemovitosti'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                       {renderRealEstate()}
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                <Pagination />
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop />
                </div>
                {/* End Back To Top */}

                 {/* Start Contact Area */}
                <div id="contact" className="fix">
                    <div className="rn-contact-area ptb--120 bg_color--1">
                        <ContactOne />
                    </div>
                </div>
                {/* End Contact Area */}

                <Footer /> 

            </React.Fragment>
        )
}
export default RealEstatePage;
