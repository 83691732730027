  import React from 'react'
  import { Field, reduxForm } from 'redux-form'
  import { connect } from 'react-redux';
  import Form from 'react-bootstrap/Form';
  import Button from 'react-bootstrap/Button';
  import Col from 'react-bootstrap/Col';
  import Row from 'react-bootstrap/Row';
  import NumberFormat from 'react-number-format';
  import { useSelector, useDispatch } from 'react-redux';
  import Slider, { SliderTooltip } from 'rc-slider/lib/Range';
  import 'rc-slider/assets/index.css';
  import 'rc-tooltip/assets/bootstrap.css';
  import './Services.css';
  import CustomCheckBox from './CustomCheckBox';
  
  import { SET_REAL_ESTATE_VALUATION_STEP, SET_REAL_ESTATE_ESTIMATE} from '../../actions/types';
  
  const { Handle } = Slider;
  
  function validate(values, props){
      const errors = {}
      if (!values.druh_nemovitosti) {
        errors.druh_nemovitosti = 'Povinné'
      }else if (values.druh_nemovitosti === '') {
        errors.druh_nemovitosti = 'Vyberte druh nemovitosti'
      }
  
      if (!values.stav_nemovitosti) {
          errors.stav_nemovitosti = 'Povinné'
        }else if (values.stav_nemovitosti === '') {
          errors.stav_nemovitosti = 'Vyberte stav nemovitosti'
        }
  
        if (!values.typ_vlastnictvi) {
          errors.typ_vlastnictvi = 'Povinné'
        }else if (values.typ_vlastnictvi === '') {
          errors.typ_vlastnictvi = 'Vyberte typ vlastnicví'
        }
        if (!values.dispozice) {
            errors.dispozice = 'Povinné'
          }else if (values.dispozice === '') {
            errors.dispozice = 'Vyberte dispozici'
          }
          if (!values.velikost) {
            errors.velikost = 'Povinné'
          }else if (values.velikost === 0) {
            errors.velikost = 'Velikost nemůže být nulová'
          }else if (values.velikost <0) {
            errors.velikost = 'Velikost nemůže být záporná'
          }
    
      return errors
    }
  function renderOptions(options){
    return options.map(item => {
        if(item.id === 0){
          return(
              <option key={item.id} value={''}>{item.name} </option>
          );
        }
        return(
            <option key={item.id} value={item.name}>{item.name}</option>
        );
    })
  }
  function renderSelect({ input, options, label, meta: { touched, error }}){
      return(
          <>
              <Form.Label>{label}</Form.Label>
              <Form.Control value={options[0]} as={'select'} {...input} size="sm"  className="shadow-sm"
              isInvalid={touched && error}
              isValid={touched && !error}>
                  {renderOptions(options)}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
              {error}
              </Form.Control.Feedback>
          </>
      );
   
  }
   
  
  function renderField({ input, customAS, label, customRows, type, placeholder, meta: { touched, error } }){
 
    return(
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Control  {...input} 
          placeholder={placeholder} 
          type={type}
          as={customAS === null? null : customAS}
          rows={customRows === null? null : customRows}
          isInvalid={touched && error}
          isValid={touched && !error}
          className="shadow-sm "
          />
          <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </>
    );
}
  function renderRadio({ label, meta: { touched, error }}){
      return(
          <>
               <Form.Check
            required
            label={label}
              />
            <Form.Control.Feedback type="invalid">
                  {error}
              </Form.Control.Feedback>
          </>
      );
  }

  const ReduxSlider = props => {
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
      const { input: { value, onChange } } = props
      return (
          <Slider
              defaultValue={props.input.value}
              onChange={(value) => dispatch({type: props.actionType, payload: value[0]})}
              {...props} 
              value={props.sliderValue}
          />
      )
  }
  
  var RealEstateForm = (props) =>{
      const dispatch = useDispatch();
  
      const { handleSubmit, valid, initialValues, t} = props;
      const appUI = useSelector(state => state.appUI);
  
      
        
      function handleFormSubmit(formValues){
          props.handleFormSubmit(formValues);
      }
      const druhNemovitostiArray = [
          {id: 0, name: 'Vyberte druh..'},
          {id: 1, name: 'Dům'},
          {id: 2, name: 'Byt'},
          {id: 3, name: 'Pozemek'},
          {id: 4, name: 'Komerční objekt'},
          {id: 5, name: 'Kancelářské prostory'},
          {id: 6, name: 'Chalupa / Chata'},
          {id: 7, name: 'Developerský projekt'},
      ]
      const stavNemovitostiArray = [
          {id: 0, name: 'Vyberte stav..'},
          {id: 1, name: 'Velmi dobrý'},
          {id: 2, name: 'Dobrý'},
          {id: 3, name: 'Špatný'},
          {id: 4, name: 'Ve výstavbě'},
          {id: 5, name: 'Novostavba'},
          {id: 6, name: 'Před rekonstrukcí'},
          {id: 7, name: 'Po rekonstrukci'},
      ]
  
      const typValstnictviArray = [
          {id: 0, name: 'Vyberte typ..'},
          {id: 1, name: 'Osobní'},
          {id: 2, name: 'Družstevní'},
      ]
      const dispoziceArray = [
        {id: 0, name: 'Vyberte počet..'},
        {id: 1, name: '1'},
        {id: 2, name: '2'},
        {id: 3, name: '3'},
        {id: 4, name: '4'},
        {id: 5, name: '5'},
        {id: 6, name: 'Atypické'},
    ]

      // these props will get passed to the morgageSliders
      const realEstatEstimateSliderProps = {
          
      
          defaultValue:[100000],
          marks:{
              100000: '100 000',
              5000000: '5 mil',
              10000000: '10 mil',
              15000000: '15 mil',
              20000000: '20 mil'
          },
          pushable:true,
          min:100000,
          max:20000000,
          step: 10000,
      }
  
     
      return (
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
             
              <hr />
                  {/* Morgage amount slider */}
                <Form.Group className="mb-3 shadow slider-section">
                <div className="box-header small-space-bottom">
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column md="auto" >
                    Odhadovaná cena:
                    </Form.Label>
                    <Col md="auto">
                    <NumberFormat 
                        thousandSeparator={true}
                        suffix={' Kč'}
                        value={appUI.realEstateEstimate}
                        onValueChange={(value) => dispatch({type: SET_REAL_ESTATE_ESTIMATE, payload: value.value})}
                        className="border-end-0 border-start-0"
                    />
                    </Col>
                </Form.Group>
                </div>
                        <div className="box-body">
                            <Field
                                name="morgageSlider"
                                component={ReduxSlider}
                                props={realEstatEstimateSliderProps}
                                actionType={SET_REAL_ESTATE_ESTIMATE}
                                sliderValue={[appUI.realEstateEstimate]}
                            />
                        </div>
                </Form.Group>
                
                <div className="mb-3 shadow contact-section">
                <span className="d-flex justify-content-center"><h5 className="fw-light">Základní informace</h5></span>
              <Row className="mb-3  ">
                <Form.Group as={Col} md="3">
                    <Field name="druh_nemovitosti" type="select"  component={renderSelect} label="Druh nemovitosti" options={druhNemovitostiArray}/>
                </Form.Group>

                <Form.Group as={Col} md="3" >
                    <Field name="stav_nemovitosti" type="select"  component={renderSelect} label="Stav nemovitosti" options={stavNemovitostiArray}/>
            
                </Form.Group>
                <Form.Group  as={Col} md="3" >
                <Field name="typ_vlastnictvi" type="select"  component={renderSelect} label="Typ vlastnictví" options={typValstnictviArray}/>
                </Form.Group>
                <Form.Group  as={Col} md="3" >
                <Field name="dispozice" type="select"  component={renderSelect} label="Počet pokojů" options={dispoziceArray}/>
                </Form.Group>
            </Row>
            <Row className=" justify-content-center">
                <Col></Col>
                <Col sm="12" md="4">
                <Form.Group >
                    <Field name="velikost" type="number" component={renderField} label="Velikost"  placeholder="20 m²"/>
                </Form.Group>
                </Col>
                <Col></Col>
            </Row>
           
        </div>
        <div className="mb-3 shadow contact-section">
                <span className="d-flex justify-content-center"><h5 className="fw-light">Dodatečné informace</h5></span>
                <Form.Group>
                    <div>
                    <Form.Label>Ostatní podrobnosti</Form.Label>
                    </div>
                    <CustomCheckBox name="balkon" inline={true} label="Balkón"/>
                    <CustomCheckBox name="bazen" inline={true} label="Bazén"/>
                    <CustomCheckBox name="garaz" inline={true} label="Garáž"/>
                    <CustomCheckBox name="parvkovaci_stani" inline={true} label="Parkovací stání"/>
                    <CustomCheckBox name="terasa" inline={true} label="Terasa"/>
                    <CustomCheckBox name="sklep" inline={true} label="Sklep"/>
                </Form.Group>
                <Form.Group >
                    <Field name="popis"  type="text" customAS="textarea" customRows={3} component={renderField}  label="Vaše zpráva a podrobnosti o nemovitosti"/>
                </Form.Group>
            </div>
               
              <span  className="d-flex justify-content-end">
              <Button size="lg" block  type="submit" 
                  variant="outline-primary"
                  className="fw-light shadow-lg next-button border-0 "
                  >
                      Bezplatná konzultace
              </Button>
              </span>
             
           
          </Form>
      )
  }
  
  export default reduxForm({
      form: 'realEstateForm', // a unique identifier for this form
      
      initialValues: {
          morgageSlider: [100000],
      },
      validate            // <--- validation function given to redux-form
    })(RealEstateForm)
  
