import React from 'react'   

import { useDispatch, useSelector } from 'react-redux';
import {SET_SHOW_MODAL, SET_MORGAGE_STEP, SET_REAL_ESTATE_VALUATION_STEP} from '../../actions/types';

import {BsX, BsArrowLeft} from 'react-icons/bs';
import { BiCalculator } from "react-icons/bi";
import { GoHome } from "react-icons/go";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import MorgageEstimate from '../onlineServices/MorgageEstimate';
import RealRestateValuation from '../onlineServices/RealRestateValuation';
import './Modal.css';

function ServiceModal(){
  const dispatch = useDispatch();
  const appUI = useSelector(state => state.appUI);

  function renderModalTitle(){
    if(appUI.modalServiceType === 'morgage_estimate'){
      return (
        <Row className="justify-content-md-center">
        <Col md="auto" className="my-auto"><BiCalculator size={30}/> </Col>
        <Col md="auto" className="header-col"><h3 className="fw-light">Hypoteční kalkulačka</h3></Col>
        </Row>
      )
    }
    else if(appUI.modalServiceType === 'real_estate_valuation'){
      return (
        <Row className="justify-content-md-center">
        <Col md="auto" className="my-auto"><GoHome size={30}/> </Col>
        <Col md="auto" className="header-col"><h3 className="fw-light">Ocenění nemovitosti</h3></Col>
        </Row>
      )
    }
  }
  
  function renderBackButton(){
    if((appUI.morgageEstimateServiceStep === 2 && appUI.modalServiceType === 'morgage_estimate') || ( appUI.realEstateEvaluationStep === 2 && appUI.modalServiceType === 'real_estate_valuation')){
      let actionType= SET_REAL_ESTATE_VALUATION_STEP;
      if(appUI.modalServiceType === 'morgage_estimate')
      actionType= SET_MORGAGE_STEP;
      return(
        <>
         <Button
            onClick={() =>  dispatch({type: actionType, payload: 1})}
            variant="link"
            className="dark-a "
          ><BsArrowLeft size={25}/>
          </Button>
        </>
      );
    }
  }

  function renderModalBody(){
    if(appUI.modalServiceType === 'morgage_estimate'){
      return <MorgageEstimate />;
    }
    else if(appUI.modalServiceType === 'real_estate_valuation'){
      return <RealRestateValuation />;
    }
  }

  function handleCloseButtonClick(){
    dispatch({type: SET_SHOW_MODAL, payload: {show: false, service: ''}})
    // If in completed stage reset service
    if(appUI.morgageEstimateServiceStep === 3 && appUI.modalServiceType === 'morgage_estimate'){
      dispatch({type: SET_MORGAGE_STEP, payload: 1});
    } else if(appUI.realEstateEvaluationStep === 3 && appUI.modalServiceType === 'real_estate_valuation'){
      dispatch({type: SET_REAL_ESTATE_VALUATION_STEP, payload: 1});
    }
  }

    return(
        <>
         <Modal
        show={appUI.showServiceModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header  className="text-center border-0" > 
        <Container>
        <Row className="justify-content-md-center ">
            <Col className="d-flex justify-content-start">
            <Button
                            onClick={() =>  handleCloseButtonClick()}
                            variant="link"
                            className="dark-a "
                    ><BsX size={25}/>
                    </Button>
                    {renderBackButton()}
            </Col>
                <Col md="auto" className="text-center">
                {renderModalTitle()}
                </Col>
                <Col>
              
                </Col>
            </Row>
            </Container>
        </Modal.Header>
        {renderModalBody()}
      </Modal>
        </>
    )
}

export default ServiceModal;