import React from "react";
import {  useDispatch, useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import ContactForm from "./MainContactForm";
import {submitContactData} from '../../actions/onlineServiceActions';
import Loading from "../../component/common/Loading";
import ContactSubmited from "./ContactSubmited";

function ContactOne(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);

    const textContent = appUI.realEstateAgentTexts.personal.homePage.text;

    function handleSubmit(formData){
        dispatch(submitContactData(formData));
    }
    function renderContent(){
        if(appUI.submittingMainContactData){
            return <Loading message="Odesílání Vaší zprávy.." />;
        }
        else if(appUI.submitContactMessage !== ''){
            return <ContactSubmited />;
        }
        return(
            <>
                <div className="section-title text-left mb--50">
                                <h2 className="title text-center">Kontaktujte mě</h2>
                                <div><p className="text-center">Telefon: <a href={textContent.hrefMobile}>{textContent.contactMobile}</a></p> </div>
                                <div><p className="text-center">E-mail: <a href={textContent.hrefEmail}>{textContent.contactEmail}</a></p> </div>
                                {textContent.contactAdress === '' ? null : <div><p className="text-center">Pobočka: <a href="javascript:;">{textContent.contactAdress}</a></p> </div>}
                </div>
                <div className="form-wrapper">
                    <ContactForm handleFormSubmit={handleSubmit}/>
                </div>
            </>
        );
    }
       
        return(
            <div className="contact-form--1">
                <ScrollAnimation animateIn="fadeInRight" duration={3} animateOnce={true}>
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                           
                           {renderContent()}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={appUI.realEstateAgentTexts.personal.homePage.img.contactMe} alt={appUI.realEstateAgentTexts.personal.homePage.img.logoAlt}/>
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
            </div>
        )
}
export default ContactOne;