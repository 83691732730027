import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {SET_SHOW_MODAL, SET_MORGAGE_STEP, SET_REAL_ESTATE_VALUATION_STEP} from '../../actions/types';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-scroll';

import './Services.css';

function FormCompleted(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    let serviceType= appUI.modalServiceType === 'morgage_estimate' ? 'hypotéky' : 'realitní služby';

    function handleContactButtonClick(){
        document.querySelector('.header-wrapper').classList.remove('menu-open');
         dispatch({type: SET_SHOW_MODAL, payload: {show: false, service: ''}});
         if(appUI.modalServiceType === 'morgage_estimate'){

            dispatch({type: SET_MORGAGE_STEP, payload: 1});
         }else{
            dispatch({type: SET_REAL_ESTATE_VALUATION_STEP, payload: 1});
         }
    }
    if(appUI.submitServiceMessage === 'success'){
    return(
        <>
            <div className="completed-modal-body ">
                <hr/>
                <div className="contact-section shadow space-bottom">
                    <span className="d-flex justify-content-center text-center"><h2 className="text-center fw-light">Děkuji,</h2></span>
                    <span className="d-flex justify-content-center text-center"><h3 className="text-center fw-light">že jste využili online služby.</h3></span>
                </div>
               
                <br/>
                <div className="contact-section shadow">
                    <span className="d-flex justify-content-center"><p className="lead text-center">V blízké době se Vám ozve specialista na {serviceType}.</p></span>
                    <br/>
                    <hr/>
                    <span className="d-flex justify-content-center"><p className="fw-light text-center small-paragraph">Pokud máte zájem o další služby nebo konzultaci,<br/> neváhejte mě kontaktovat.</p></span>
                    
                    <br/>
                    <span className="d-flex justify-content-center">
                        <Link 
                            to={`contact`} 
                            smooth={true} offset={-100}>
                                <Button size="lg" 
                                    variant="outline-primary"
                                    className="fw-light shadow-lg next-button border-0 "
                                    onClick={() => handleContactButtonClick()}
                                >
                                    Zobrazit kontakt
                                </Button>
                        </Link>
                    </span>
                </div>
            </div>  
        </>
    )
    }
    else if(appUI.submitServiceMessage === 'error'){
        return(
            <>
                <div className="completed-modal-body ">
                    <hr/>
                    <div className="contact-section shadow space-bottom">
                        <span className="d-flex justify-content-center text-center"><h2 className=" fw-light text-center">Děkuji,</h2></span>
                        <span className="d-flex justify-content-center text-center "><h3 className=" fw-light text-center">že jste využili online služby.</h3></span>
                    </div>
                   
                    <br/>
                    <div className="contact-section shadow">
                        <span className="d-flex justify-content-center "><p className="lead text-danger text-center">Bohužel Váš požadavek se nám nepodařilo zpracovat.</p></span>
                        <br/>
                        <hr/>
                        <span className="d-flex justify-content-center"><p className="fw-light text-center small-paragraph">Zkontrolujte prosím vaše připojení k internetu a zkuste naší službu později. <br/> Popřípadě mě neváhejte kontaktovat na přímo.</p></span>
                        
                        <br/>
                        <span className="d-flex justify-content-center">
                            <Link 
                                to={`contact`} 
                                smooth={true} offset={-100}>
                                    <Button size="lg" 
                                        variant="outline-primary"
                                        className="fw-light shadow-lg next-button border-0 "
                                        onClick={() => handleContactButtonClick()}
                                    >
                                        Zobrazit kontakt
                                    </Button>
                            </Link>
                        </span>
                    </div>
                </div>  
            </>
        )
    }
    else return null;
}

export default FormCompleted;