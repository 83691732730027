import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { SET_REAL_ESTATE_PAGINATION} from '../../actions/types';

export default function Pagination() {
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    const numberOfProperties = appUI.realEstateList.length;
    let numberOfPages = Math.ceil(numberOfProperties / 6);
    console.log(numberOfProperties);
    console.log(numberOfPages);
    
    
    function renderPagination(){
        if(numberOfPages <= 1){
            return null;
        }
        let totalyImportantArray = [];
        for (let index = 1; index <= numberOfPages; index++) {
            totalyImportantArray.push(index);
        }
        console.log(totalyImportantArray);
        return totalyImportantArray.map(index => {
            let activeStyle = index === appUI.realEstateCurrentPage ? 'active' : '';
            console.log(index);
            return(
                    <li
                    key={index}
                    onClick={() => dispatch({type: SET_REAL_ESTATE_PAGINATION, payload: index})}
                    className={activeStyle}
                    >
                        <Link to="#">{index}</Link>
                    </li>
          
            )
        })
    }
    return (
        <div className="rn-pagination text-center">
            <ul className="page-list">
                {renderPagination()}
            </ul>
        </div>
    )
}